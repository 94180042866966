.teaser {
	color:$lightgrey-dd;
	margin: 5px 0;

	a {
		color:$lightgrey-dd;
	}

	.image-wrap {
		display: inline-block;
		background: $border-color-light;
		position: relative;
		width:100%;
		overflow:hidden;


		.img-text {
			display: table;
			height:100%;
			width:100%;
			padding:0 20px;
			font-family:$font-secondary;
			font-size:1.8em;
			line-height:1.2;
      min-height: 120px;

			strong {
				font-family:OfficinaSerifITCTTBold, serif;
			}

			&.style-piktogramm {
				text-align: center;
				font-size:6em;
				line-height: 1;
				padding:28px 0;
			}

			.inner-table {
				display: table-cell;
				vertical-align: middle;
			}
		}


		img {
			float:left;
			width:100%;
			height: auto;
			max-width:none;
		}
	}

	.text-wrap {
		display: inline-block;
		width:100%;

		header * {
			margin:10px 0;
		}

		p {
			margin-top:0;
			font-size:.9em;
		}
	}
}


.teaser-outgoing {
	position: relative;
	width:100%;
	/*
	width:150%;

	@media (min-width:$breakpoint-small) {
		width:125%;
	}

	@media (min-width:$breakpoint-medium) {
		width:128%;
	}

	@media (min-width:$breakpoint-large) {
		width:120%;
	}

	@media (min-width:$breakpoint-xlarge) {
		width:112%;
	}

	@media (min-width:1700px) {
		width:108%;
	}
	*/

	.slick-prev {
		display:none !important;

		@media (min-width:$breakpoint-xlarge) {
			//display:block !important;
			left: -90px;
		}
	}

	.slick-next, .slick-prev {
		top:0;
		@include borderradius(0);
		width:60px;
		height:60px;
		background: $primary-l;
		font-size:20px;
		text-align: center;

		&:hover {
			background: $primary-d;
		}
		/*
		width:40px;
		height:40px;
		background:$primary;
		@include borderradius(100px);

		top: 30%;

		@media (min-width:$breakpoint-small) {
			top: 24%;
		}

		@media (min-width:$breakpoint-medium) {
			top: 33%;
		}

		@media (min-width:$breakpoint-large) {
			top: 27%;
		}

		@media (min-width:$breakpoint-xlarge) {
			top: 27%;
		}

		@media (min-width:1450px) {
			top: 26%;
		}

		@media (min-width:1750px) {
			top: 32%;
		}

		@media (min-width:$breakpoint-xlarge) {
			width:70px;
			height:70px;
		}
		*/
		/*
		&:hover {
			background:$primary-l;
		}
		&:active {
			background:$primary-d;
		}
		*/

		&:before {
			display: inherit;
			font-size: 26px;
		}
	}

	.slick-next {
		right:0;

		@media(min-width:$breakpoint-xlarge){
			right:10px;
		}
		/*
		right:38%;

		@media (min-width:$breakpoint-small) {
			right:23%;
		}

		@media (min-width:$breakpoint-medium) {
			right:25%;
		}

		@media (min-width:$breakpoint-large) {
			right:18%;
		}

		@media (min-width:$breakpoint-xlarge) {
			right:14%;
		}

		@media (min-width:1450px) {
			right:14%;
		}

		@media (min-width:1750px) {
			//right: 15%;
		}

		@media (min-width:1700px) {
			right: 11%;
		}
		*/
	}

	.slick-slide {
		margin-right:20px;
		@media(min-width:$breakpoint-xlarge) {
			margin-right:30px;
		}
	}

	.teaser {
		width:100%;
	}
}


.smaller-text-pikto {

	.teaser .image-wrap .img-text.style-piktogramm {
		font-size:5em;
	}

	.teaser .image-wrap .img-text {
		font-size:1.4em;
	}
}
