@mixin borderradius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

@mixin opacity($value) {
  -webkit-opacity: $value;
     -moz-opacity: $value;
      -ms-opacity: $value;
       -o-opacity: $value;
          opacity: $value;
}

@mixin boxshadow($value) {
  -webkit-box-shadow: $value;
     -moz-box-shadow: $value;
      -ms-box-shadow: $value;
       -o-box-shadow: $value;
          box-shadow: $value;
}

@mixin transformRotate($value) {
  -webkit-transform: rotate($value);
     -moz-transform: rotate($value);
      -ms-transform: rotate($value);
       -o-transform: rotate($value);
          transform: rotate($value);
}


@mixin transformScale($value) {
  -webkit-transform: scale($value);
     -moz-transform: scale($value);
      -ms-transform: scale($value);
       -o-transform: scale($value);
          transform: scale($value);
}

@mixin transformScaleRotate($value1, $value2) {
  -webkit-transform: scale($value1) rotate($value2);
     -moz-transform: scale($value1) rotate($value2);
      -ms-transform: scale($value1) rotate($value2);
       -o-transform: scale($value1) rotate($value2);
          transform: scale($value1) rotate($value2);
}

@mixin ease($value) { // $value = all 0.2s
  -webkit-transition: $value ease-in;
     -moz-transition: $value ease-in;
      -ms-transition: $value ease-in;
       -o-transition: $value ease-in; 
          transition: $value ease-in;
}

@mixin twoCol($cols, $margin) {
  -webkit-column-count: $cols;
  -webkit-column-gap: $margin;
  -moz-column-count: $cols;
  -moz-column-gap: $margin;
  column-count: $cols;
  column-gap: $margin;
}

@function str-replace($string, $substr, $newsubstr, $all: 0) {
  $position-found: str-index($string, $substr);
  $processed: ();

  @while ($position-found and $position-found > 0) {
    $length-substr: str-length($substr);
    @if (1 != $position-found) {
      $processed: append($processed, str-slice($string, 0, $position-found - 1));
    }
    $processed: append($processed, $newsubstr);
    $string: str-slice($string, $position-found + $length-substr);

    $position-found: 0;

    @if ($all > 0) {
      $position-found: str-index($string, $substr);
    }
  }

  $processed: append($processed, $string);
  $string: "";

  @each $s in $processed {
    $string: #{$string}#{$s};
  }

  @return $string;
}