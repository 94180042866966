table.events-table {

	th {
		text-align: left;
		padding-left:10px;
	}
	
	td {

		&.bezeichnung {
			width:20%;
		}

		&.datum {
			width:14%;
		}

		&.actions {
			width:12%;
		}
	}
}