.detail-article {

	.mainimage {
		width:99%;
		float:left;
		margin:.5%;
		padding:5px;
		text-align: center;
		border:1px solid $border-color-light;
		line-height: 0;

		img {
			display: inline-block;
			width: auto;
			max-height:220px;
		}
	}

	.thumbnail-images {
		display: inline-block;
		width:100%;

		.image-wrap {
			float:left;
			width:24%;
			margin:.5%;
			padding:5px;
			text-align: center;
			border:1px solid $border-color-light;
			line-height: 0;

			img {
				display: inline-block;
				width:auto;
				max-height:80px;
			}
		}
	}
}


.description-trigger {
	@include borderradius(5px);
	background: $border-color-light;
    padding: 15px 20px;
	display:block;
	position: relative;
	cursor: pointer;
	@media(min-width:$breakpoint-medium) {
		display:none !important;
	}

	&:before {
		font-family: "FontAwesome";
		content:"\f0fe";
		float: left;
		margin-right:10px;
	    color:$secondary;
	}

	&.toggled:before {
		content:"\f146";
	}
}

.description-content {
	display:none;
	@media(min-width:$breakpoint-medium) {
		display:block !important;
	}
}