img.alignright {
	float:right;
	margin:0 0 20px 20px;
}

img.alignleft {
	float:left;
	margin:0 20px 20px 0;
}

img.aligncenter {
	display: block;
	margin:30px auto;
}

.text-block {

	img {
		border: 0;
	}

	.wp-caption-text {
		color:$lightgrey-dd;
		font-size:.9em;
	}


	.gallery-item {
		padding:10px;

		@media(max-width:$breakpoint-small-max) {
			width:100% !important;
		}
	}
}