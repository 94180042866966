$placeholder-color: #8592A9;

::-webkit-input-placeholder { color:$placeholder-color; }
::-moz-placeholder { color:$placeholder-color; }
:-ms-input-placeholder { color:$placeholder-color; }
input:-moz-placeholder { color:$placeholder-color; }



input, textarea, select, label, button {
	font-family:inherit;
	font-size:inherit;
	font-weight:inherit;
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	-o-box-shadow:none;
	box-shadow:none;
	outline:none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;

	&[readonly] {
		cursor: not-allowed;
	}
}

label {
	display: inline-block;
	width: 100%;
}

input, textarea {
	border:1px solid $border-color-light;
	width:100%;
}


textarea {
	padding:15px 20px;
}


input {
	font-size:.8em;
	font-weight:normal;
	padding:15px 20px;
	color:$primary;
	height:47px;

	&.btn {
		width:auto;
	}

	&.transparent {
		background:none;
		border:none;
	}

	&[type="date"]{
		padding:12px 20px 13px;
	}

	&[type="checkbox"], &[type="radio"]{
		@include borderradius(100px);
		width:30px;
		height:30px;
		padding:8px 0 0 0;
		margin:0 10px 0 0;
		text-align: center;
		float:left;
		cursor:pointer;

		&:checked {
			&:before {
				font-family:"FontAwesome";
				content:"\f00c";
				font-size: 12px;
				color:$secondary;
			}
		}

		& + span {
			padding-top: 5px;
			display: inline-block;
		}
	}

	&[type="radio"]:checked {
		&:before {
			content:"\f111";
		}
	}
}

/*
.select-wrap {
	position: relative;

	&:after {
		font-family: "FontAwesome";
		color:$placeholder-color;
		content:"\f0dc";
		position: absolute;
		top:12px;
		right:18px;
		font-size:.9em;
	}
}
*/

select {
	border:1px solid $border-color-light;
	background: #fff;
	@include borderradius(0);
	font-size:.8em;
	font-weight:normal;
	padding:15px 20px;
	color:$placeholder-color;
	height:47px;
	cursor: pointer;

	background-image: url(../img/select-sort.svg);
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: right center;
	padding-right:25px;

	option {
		color:$primary;
	}
}


.checkbox-label {
	padding: 7px 20px 8px;
	float: left;
	font-size: .8em;
	border:1px solid $border-color-light;
	width:100%;

	span {
		float:left;
		padding-top:7px;
	}
}

.input-with-button {
	float:left;
	width:100%;
	padding-right:40px;
	position: relative;

	input {
		float:left;
		width:100%;
		padding: 18px 20px 17px;
	}

	button {
		position: absolute;
		right:0;
		top:0;
		bottom:0;

		background: $secondary;
		color:#fff;
		padding:0;
		width:40px;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			background: $secondary-l;
		}
		&:active {
			background: $secondary-d;
		}
	}
}

.form-row {
	display: inline-block;
	width:100%;
}

.margined-row {
	margin:4px 0;

	&.margined-row-big {
		margin:10px 0;
	}
}

.form-item {
	@include borderradius(5px);
	background:$border-color-light;
	padding:4px 10px;
	display: inline-block;
	margin-right:2px;
	margin-bottom:2px;
	cursor:pointer;

	@media (min-width:$breakpoint-small) {
		margin-right:5px;
		margin-bottom:5px;
	}

	.checkbox {
		display: inline-block;
		margin-right:10px;

		&:before {
			font-family: "FontAwesome";
			content:"\f0c8";
			color:#fff;
			//font-size:12px;
		}

		&.active:before {
			content:"\f14a";
			color:$primary;
		}
	}

}

#login {

	.login-item {
		margin:4px 0;
	}

}