#fullpage-ajaxloader {
	position: fixed;
	top:50px;
	left:10px;
	z-index:999;

	.loading-wrap {
		width:120px;

		.status {
			margin-left:0;
			left:10px;
		}
	}
}

.loading-wrap {
	text-align: center;
	color:#fff;
	height:75px;
	position: relative;
	margin:10px 0;
	display:none;
	padding:10px 0;

	* {
		color:#fff;
	}

	.status {
		@include transformScale(0);
		position: absolute;
		left: 50%;
		top:10px;
		width: 100px;
		margin-left: -50px;

		.inner {
			@include borderradius(100000px);
			background: $primary;
			display: inline-block;
			width: 100px;
			padding:10px 15px;

			.icon {
				font-size:25px;
			}
		}

		&.status-success .inner {
			background: $green;
		}


		&.status-error .inner {
			background: $secondary;
		}


		&.status-warning .inner {
			background: $orange;
		}
	}
}
