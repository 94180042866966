.btn, .uk-button {
	@include borderradius($button-border-radius);
	@include ease($button-easing);
	background:$primary;
	display:inline-block;
	padding:13px 24px !important; 
	font-size:.85em;
	color:#fff;
	margin-bottom:1px;
	margin-right:3px;
	cursor: pointer;

	i {
		margin-right:5px;

		&.icon-right {
			margin-right: 0;
			margin-left: 5px;
		}
	}

	&.btn-icon {
		i {
			margin-right:0;
			margin-left:0;
		}
	}

	&:hover { background: $primary-l; color:#fff; }
	&:active { background: $primary-d; color:#fff; }
	&:focus { background: $primary; color:#fff; }

	&.btn-inverse {
		background:#fff !important;
		color:$primary;

		&:hover { color: $primary-l; }
		&:active { color: $primary-d; }
		&:focus { color: $primary; }
	}

	&.btn-block {
		text-align: center;
		width:100%;
		margin-right:0;
	}

	&.btn-small {
		padding:8px 16px !important;
	}

	&.btn-large {
		font-size: 1em;
		padding:16px 28px !important;
	}

	&.btn-rounded {
		@include borderradius(200px);
	}


	&.btn-secondary {
		background: $secondary;

		&:hover { background: $secondary-l; }
		&:active { background: $secondary-d; }
		&:focus { background: $secondary; }

		&.btn-inverse {
			background:#fff !important;
			color:$secondary;

			&:hover { color: $secondary-l; }
			&:active { color: $secondary-d; }
			&:focus { color: $secondary; }
		}
	}

	&.btn-grey, &.uk-modal-close {
		background: $grey;

		&:hover { background: $grey-l; }
		&:active { background: $grey-d; }
		&:focus { background: $grey; }

		&.btn-inverse {
			background:#fff !important;
			color:$grey;

			&:hover { color: $grey-l; }
			&:active { color: $grey-d; }
			&:focus { color: $grey; }
		}
	}


	&.btn-lightgrey {
		background: $lightgrey;
		color: $grey;

		&:hover { background: $lightgrey-l; }
		&:active { background: $lightgrey-d; }
		&:focus { background: $lightgrey; }

		&.btn-inverse {
			background:$grey !important;
			color:$lightgrey;

			&:hover { color: $lightgrey-l; }
			&:active { color: $lightgrey-d; }
			&:focus { color: $lightgrey; }
		}
	}


	&.btn-green, &.add-to-cart, &.selected-to-cart, &.sendorder {
		background: $green;

		&:hover { background: $green-l; }
		&:active { background: $green-d; }
		&:focus { background: $green; }

		&.btn-inverse {
			background:#fff !important;
			color:$green;

			&:hover { color: $green-l; }
			&:active { color: $green-d; }
			&:focus { color: $green; }
		}
	}


	&.btn-orange {
		background: $orange;

		&:hover { background: $orange-l; }
		&:active { background: $orange-d; }
		&:focus { background: $orange; }

		&.btn-inverse {
			background:#fff !important;
			color:$orange;

			&:hover { color: $orange-l; }
			&:active { color: $orange-d; }
			&:focus { color: $orange; }
		}
	}


	&.btn-disabled {
		background: #E8E8E8 !important;
		color: #a0a0a0 !important;
		cursor:not-allowed;
	}

}