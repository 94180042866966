// Typo Definition

body {
	font-family:$font-primary;
	font-size:$font-smartphone-size;
	font-weight:$font-base-weight;
	color:$grey-l;
	line-height:1.43;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-ms-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	font-smoothing: antialiased;

	@media (min-width:$breakpoint-small) {
		font-size:$font-tablet-size;
	}

	@media (min-width:$breakpoint-large) {
		font-size:$font-base-size;
	}
}

strong {
	font-family:OfficinaSansITCTTBold, sans-serif;
	font-weight: normal;
}

i {
	font-family:OfficinaSansITCTTBookItalic, sans-serif;
}

hr {
	border:none;
	border-top:1px solid $border-color-light;
	margin: 30px 0;

	@media(min-width:$breakpoint-medium) {
		margin: 50px 0;
	}
}

.two-col {
	@media(min-width:$breakpoint-small) {
		-webkit-column-count: 2;
		-webkit-column-gap: 30px;
		-moz-column-count: 2;
		-moz-column-gap: 30px;
		column-count: 2;
		column-gap: 30px;
		//margin:10px 0;
	}

	:first-child {
		margin-top:0;
	}
}

.three-col {
	@media(min-width:$breakpoint-small) {
		-webkit-column-count: 3;
		-webkit-column-gap: 30px;
		-moz-column-count: 3;
		-moz-column-gap: 30px;
		column-count: 3;
		column-gap: 30px;
		//margin:10px 0;
	}

	:first-child {
		margin-top:0;
	}
}

.four-col {
    @media(min-width:$breakpoint-small) {
        -webkit-column-count: 4;
        -webkit-column-gap: 30px;
        -moz-column-count: 4;
        -moz-column-gap: 30px;
        column-count: 4;
        column-gap: 30px;
        //margin:10px 0;
    }

    :first-child {
        margin-top:0;
    }
}

.border-light {
	border:1px solid $border-color-light;
	padding:15px;
}

.border-light-top {
	border-top:1px solid $border-color-light;
	padding-top:10px;
}

.border-light-right {
	border-right:1px solid $border-color-light;
	padding-right:10px;
}

.border-light-bottom {
	border-bottom:1px solid $border-color-light;
	padding-bottom:10px;
}

.border-light-left {
	border-left:1px solid $border-color-light;
	padding-left:10px;
}

.border-dark {
	border:1px solid $primary;
	padding:15px;
}

.border-dark-top {
	border-top:1px solid $primary;
	padding-top:10px;
}

.border-dark-right {
	border-right:1px solid $primary;
	padding-right:10px;
}

.border-dark-bottom {
	border-bottom:1px solid $primary;
	padding-bottom:10px;
}

.border-dark-left {
	border-left:1px solid $primary;
	padding-left:10px;
}


// Headlines

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family:$font-secondary;
	font-weight:normal;
	color:$primary;
	margin:25px 0;

	i {
		font-family:$font-secondary;
	}

	strong {
		font-family:OfficinaSerifITCTTBold, serif;
		font-weight: normal;
	}
}

h1, h2, h3,
.h1, .h2, .h3 {
	margin:25px 0;
	line-height: 1.2;
}

h4, h5, h6,
.h4, .h5, .h6 {
	margin:15px 0;
}


h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

@media(max-width:$breakpoint-small-max) {
	h1, .h1 { font-size: 2.6em; }
	h2, .h2 { font-size: 2.2em; }
	h3, .h3 { font-size: 1.8em; }
	h4, .h4 { font-size: 1.4em; }
	h5, .h5 { font-size: 1.2em; }
	h6, .h6 { font-size: 1em; }
}


.inline-block {
	display:inline-block;
}


// List

ul.red-list {
	list-style-type: none;
	padding:0;

	li {
		padding-left:16px;
		position: relative;

		&:before {
			font-family:"FontAwesome";
			content:"\f105";
			position: absolute;
			left:0;
			top:0;
			font-size: .8em;
			color:$secondary;
		}
	}
}


// Schriftarten

.font-primary {
	font-family:$font-primary;

	strong, b {
		font-family:OfficinaSansITCTTBold, sans-serif;
	}
}

.font-secondary {
	font-family:$font-secondary;

	strong, b {
		font-family:OfficinaSerifITCTTBold, serif;
	}
}


// Schriftschnitte

.text-light {
	font-weight:300;
}

.text-bold {
	font-family:OfficinaSansITCTTBold, sans-serif;
	font-weight: normal;
}


// Schriftgrößen

.text-big {
	font-size:$font-size-large;
}

.text-medium-big {
	font-size:1.2em;
}

.text-small {
	font-size:$font-size-small;
}


// Transform

.transform-uppercase {
	text-transform:uppercase;
}

.transform-lowercase {
	text-transform:lowercase;
}

.transform-none {
	text-transform:none;
}

.letter-spacing {
	letter-spacing: 2px;
}

.letter-spacing-big {
	letter-spacing: 4px;
}

.letter-spacing-none {
	letter-spacing: normal;
}


// Farben

.color-primary {
	color:$primary !important;
}

.color-primary-l {
	color:$primary-l !important;
}

.color-primary-d {
	color:$primary-d !important;
}

.color-secondary {
	color:$secondary !important;
}

.color-secondary-l {
	color:$secondary-l !important;
}

.color-secondary-d {
	color:$secondary-d !important;
}

.color-grey {
	color:$grey !important;
}

.color-grey-l {
	color:$grey-l !important;
}

.color-grey-d {
	color:$grey-d !important;
}

.color-lightgrey {
	color:$lightgrey !important;
}

.color-lightgrey-l {
	color:$lightgrey-l !important;
}

.color-lightgrey-d {
	color:$lightgrey-d !important;
}

.color-white {
	color:#fff !important;
}


// Hintergründe

.background-primary {
	background:$primary !important;
	color:#fff;
}

.background-primary-l {
	background:$primary-l !important;
	color:#fff;
}

.background-primary-d {
	background:$primary-d !important;
	color:#fff;
}

.background-secondary {
	background:$secondary !important;
	color:#fff;
}

.background-secondary-l {
	background:$secondary-l !important;
	color:#fff;
}

.background-secondary-d {
	background:$secondary-d !important;
	color:#fff;
}

.background-grey {
	background:$grey !important;
	color:#fff;
}

.background-grey-l {
	background:$grey-l !important;
	color:#fff;
}

.background-grey-d {
	background:$grey-d !important;
	color:#fff;
}

.background-lightgrey {
	background:$lightgrey !important;
}

.background-lightgrey-l {
	background:$lightgrey-l !important;
}

.background-lightgrey-d {
	background:$lightgrey-d !important;
}

.background-white {
	background:#fff !important;
}

.background-black {
	background:#000 !important;
}

.background-lightblue {
	background:$border-color-light !important;
}

.background-lightblue-l {
	background:$border-color-light-l !important;
}

.background-lightblue-d {
	background:$border-color-light-d !important;
}



// Hidden Text

.hidden-text {
	text-indent: -10000px !important;
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	overflow: hidden !important;
}
