.uk-accordion {
	border-bottom:1px solid $border-color-light;

	.uk-accordion-title {
		border-top:1px solid $border-color-light;
		padding:10px 0;
		margin:0;
		display: inline-block;
		width:100%;
		font-size:1.1em;
		cursor:pointer;
		padding-left:2px;
		color:darken($border-color-light, 40%);
		@include ease(all .2s);

		&:after {
			float:right;
			font-family:"FontAwesome";
			content:"\f104";
			width:20px;
			text-align: center;
		}

		&.uk-active {
			//border-color:darken($border-color-light, 12%);

			&:after {
				content:"\f107";
			}
		}

	}

	.uk-accordion-content {
		border-top:1px solid $border-color-light;
		padding:20px 0;
		font-size:.9em;
	}
}