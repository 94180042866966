.container {
	width:100%;
	max-width:$breakpoint-xlarge;
	margin-left:auto;
	margin-right:auto;
}

main {
	padding-bottom:30px;
	overflow:hidden;
	display: inline-block;
	width:100%;
}

.more-wrap {
	margin-top:20px;
	padding-top:20px;
	border-top:1px solid $border-color-light;
	display: inline-block;
	width:100%;
}