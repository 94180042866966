.uk-modal-close.uk-close {
	position: absolute;
	width:20px;
	height:20px;
	top: -16px !important;
	right: -7px !important;
	&:before {
		font-family: "FontAwesome";
		content:"\f00d";
		font-size: 20px;
    	color: #fff;
	}	
}

.uk-modal-dialog-lightbox {

	.uk-modal-close.uk-close {
		top: -30px !important;
		right: -30px !important;
	}
}

.uk-modal-dialog {
	text-align: left !important;
	overflow: hidden !important;
	overflow-y: visible !important;
}

.uk-modal-header {
    margin-bottom: 15px;
    margin: -20px -20px 15px -20px;
    padding: 20px;
    border-bottom: 1px solid $border-color-light;
    border-radius: 4px 4px 0 0;
    background: #fafafa;

    * {
    	margin:0;
    }
}

.uk-modal-footer {
    margin-top: 15px;
    margin: 15px -20px -20px -20px;
    padding: 20px;
    border-top: 1px solid $border-color-light;
    border-radius: 0 0 4px 4px;
    background: #fafafa;
}

.uk-modal-dialog-lightbox {
	overflow-y:hidden !important;
}

.uk-lightbox-content {
	height:100%;
	text-align: center;

	img {
	    max-height: 100% !important;
		width: auto;
	}
}