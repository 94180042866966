#browsercomp {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999;

  .browsercomp-item {
    background: $secondary;
    color: $white;
    display: none;
    margin-top: 2px;
    padding: 20px;

    .browsercomp-headline {
      color: $white;
      display: block;
      margin-bottom: 10px;
      margin-top: 0;
    }

    p {
      margin: 0;
    }
  }
}

html.no-js {

  #browsercomp #browsercomp-javascript {
    display: block;
  }

  #browsercomp #browsercomp-browser {
    display: none;
  }
}

#browsercomp #browsercomp-browser {
  display: block;
}

html.csstransforms3d {

  #browsercomp #browsercomp-browser {
    display: none;
  }
}
