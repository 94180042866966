#kontaktformular {

	.send-box {
		background: $border-color-light-l;
		border: 1px solid $border-color-light;
		padding: 30px;
		margin-top: 40px;

		p {
			margin-bottom: 0;
			position: relative;
			padding-right:28px;

			img.ajax-loader {
				position: absolute;
				top: 15px;
				right:0;
			}
		}
	}
	
	input[type="submit"] {
		@include borderradius($button-border-radius);
		@include ease($button-easing);
		background: $primary;
		//width:auto;
		color:#fff;

		&:hover {
			background: $primary-l;
		}

		&:active {
			background: $primary-d;
		}
	}
}