header#main-header {

	// Loggedin

	&.logged-in {

		form {

			button {
				color:$primary;
			}
		}

		@media(min-width:$breakpoint-large) {
			&.fixed-status {
				margin-top:96px;
			
				#top-header {
					position: fixed;
					z-index:400;
					top:0;
					left:0;
					width:100%;
				}
			}
		}

		#top-header {
			background:$border-color-light;
			border-bottom: 1px solid #fff;
			color:$primary;

			.dropdown-item a.dropdown-trigger {
				@include ease(all .2s);

				&:hover {

					i:before {
						content: "\f0d7";
					}
				}

				&.active {
					i:before {
						content: "\f0d8";
					}
				}
			}

			

			.dropdown-item {
				font-size:1.25em;
				width:25%;

				@media(min-width:$breakpoint-small) {
					font-size:.85em;
					width:27.5%;
				}
				@media(min-width:$breakpoint-medium) {
					width:auto;
					font-size:1em;
				}

				&.cart-link {
					float:right;
					text-align: right;
					@media(min-width:$breakpoint-medium) {
						
					}
				}

				a {
					color:$primary;
					padding: 10px 14px;
					@media(min-width:$breakpoint-small) {
						padding: 14px;
					}

					small {
						color:$primary;
					}

					strong {
						display:none;

						@media(min-width:$breakpoint-small) {
							display: inline-block;
						}
					}
				}
			}

			@media(min-width:$breakpoint-medium) {
				.dropdown-item a {
					height:58px !important;
				}
			}
		}

		.top-slideout {
			background: #fff;
			border-bottom:1px solid #fff;
		}
	}




	// Allgemein


	.top-slideout {
		border-bottom:1px solid $border-color-light;
		float:left;
		width:100%;
		display:none;
		padding: 0 10px;

		@media(max-width:$breakpoint-small) {
			input {
				margin-bottom:5px;
			}
		}

		@media(min-width:$breakpoint-xlarge) {
			padding:0;
		}

		.container {
			position: relative;
		}

		.close-topslideout {
			display: none;
			position: absolute;
			top: -1px;
			left:50%;
			background: $border-color-light;
			@include borderradius(0 0 3px 3px);
			@include ease(all 0.2s);
			padding: 6px 15px;
			font-size: .85em;
			color: #828BA5;
			width: 110px;
			margin-left: -55px;
			text-align: center;

			&:hover {
				background: $secondary;
				color:#fff;
			}
		}

		.last-searchterms {
			padding:0;
			list-style-type: none;
			margin: 0;

			li {
				display: inline-block;
				margin-bottom:2px;
				margin-right:2px;

				a {
					display:inline-block;
					background: $primary;
					@include borderradius(5px);
					@include ease(all 0.2s);
					padding:7px 14px;
					font-size: .85em;
					color:#fff;

					&:hover {
						background: $primary-l;
					}
					&:active {
						background: $primary-d;
					}
				}
			}
		}


		.inner-wrap {
			display: inline-block;
			width:100%;
			padding: 30px 0 15px;
		}


		.left-info {
			&.border {
				@media(min-width:$breakpoint-medium){
					padding-right: 30px;
					border-right:1px solid $border-color-light;
				}
			}
		}

		.right-info {

			&.border {
				@media(min-width:$breakpoint-medium){
					padding-left: 30px;
					border-left:1px solid $border-color-light;
				}
			}

		}


		.navigation {
			@media(min-width:$breakpoint-medium){
				padding: 30px 0;
			}

			ul {
				list-style-type: none;
				padding: 0;
				margin:0;
				display: inline-block;
				width:100%;

				li {
					float:left;
					width:50%;
					@media(min-width:$breakpoint-small){
						width:50%;
					}
					@media(min-width:$breakpoint-medium){
						width:33.333%;
					}

					a {
						color:$primary;
						float:left;
						width:100%;
						padding:6px 0;

						&:hover, &.current-menu-item {
							color:$secondary;
						}
					}
				}
			}
		}

	}


	// Topbar im Header
	#top-header {
		background:#fff;
		border-bottom: 1px solid $border-color-light;
		width: 100%;
    	display: inline-block;
    	position: relative;
    	z-index: 4;

    	.login-buttons {
    		width:100%;

    		@media(min-width:$breakpoint-medium) {
    			width:auto;
    		}
    	}

		.dropdown-item a {
			float: left;
			color:$primary;
			font-family:OfficinaSansITCTTBold, sans-serif;
			//background: #d1d7e2;

			&.warenkorb {
				@media(min-width:$breakpoint-medium) {
					padding-left:0;
					padding-right:0;
				}

				&:hover {
					i:before {
						content:"\f0da";
					}
				}

			}

			span {
				display: inline-block;
				padding:6px 0;
			}

			small {
				@media(max-width:$breakpoint-medium) {
					display:none;
				}
				@include borderradius(4px);
				font-family:$font-primary;
				font-size:1em;
			    padding: 7px 14px 4px;
			    margin-left: 10px;
			    background: #fff;
			    height:auto;
			    display: inline-block;
			}

			&.active small {
				color:$primary;
			}

			i {
				width: 18px;
    			text-align: center;
			}
		}


		.forgot-password {
			font-size:.8em;
			display: inline-block;
			margin-top:8px;
		}


		.search-wrap {
			width: 50%;
			margin:0 auto;

			@media(min-width:$breakpoint-small){
				width: 45%;
			}
			@media(min-width:$breakpoint-medium){
				max-width:320px;
			}
			@media(min-width:$breakpoint-large){
				max-width:480px;
			}

			form.input-with-button {
				width: 100%;
				background: #fff;
				margin: 7px 0;
				@include borderradius(4px);
				overflow: hidden;

				@media(min-width:$breakpoint-medium){
					border-bottom: none;
				}

				input {
					width: 100%;
					margin:0;
					height:auto;
					padding: 14px 0px 11px 10px;
					font-size: 1em;

					@media(min-width:$breakpoint-medium){
						padding: 14px 35px 11px 85px;
					}
				}

				button {
					color:#fff;
				}

				a {
					position: absolute;
					top:0;
					left:0;
					background:$primary;
					@include ease(all 0.2s);
					color:#fff;
					height:100%;
					padding:13px 10px;
					font-size:13px;
					display: none;

					@media(min-width:$breakpoint-medium){
						display: block;
					}

					i {
						margin-right:0;
						margin-left:5px;
					}

					&:hover {
						background:$primary-l;
					}
					&:active {
						background:$secondary;
					}

					&.active {
						background:$secondary;

						i:before {
							content:"\f0d8";
						}
					}
				}
			}

			/*
			&.active {
				.input-with-button {
					background: $primary;
					button {
						color:#fff;
						background: darken($primary, 8%);
					}
					input {
						color:#fff;
					}
				}
			}
			*/

		}

		#login-s {

			button {
				color:#fff !important;
				height: 47px;
			}
	
		}

		.loggedin-user {
			float:left;
			font-size:.8em;
			display:inline-block;

			i {
				color:#3CBD36;
				font-size:10px;
				float:left;
				padding-top: 3px;
				margin-right:8px;
			}
		}

		.dropdown-item {
			float:left;
			width:50%;

			@media(min-width:$breakpoint-medium){
				width:auto;
			}

			@media (min-width:$breakpoint-small){
				position: relative;
			}

			a {
				padding:14px;
				display: inline-block;
				width:100%;
				font-size:1em;
				border-right:1px solid $border-color-light;

				@media(min-width:$breakpoint-medium) {
					font-size:.9em;
				}

				&.cockpit{
					background: $secondary;
					color:#fff;

					small {
						color:$secondary;
					}

					&.active small {
						color:$primary;
					}
				}

				&.active {
					background: $primary !important;
					color: #fff;
					border-right:1px solid $primary;
				}
			}

			.dropdown-content {
				position: absolute;
				background: $primary;
				color:#fff;
				right: 0;
				width: 100%;
				display:none;
				z-index: 2;
				padding:15px;

				@media (min-width:$breakpoint-small){
					width:280px;
				}

				* {
					color:#fff;
				}

				:first-child {
					margin-top:0;
				}
				:last-child {
					margin-bottom:0;
				}
			}

			&:first-child {
				a {
					border-left:1px solid $border-color-light;
				}
			}

		}

	}


	// Navigation und Logo
	#bottom-header {
		margin: $spacer-phone-medium 0 5px;
		//position: relative;

		@media (min-width:$breakpoint-small) {
			margin: 40px 0 15px;
		}


		.container {
			//position: relative;
		}

		#logo {
			position:absolute;
			width:290px;
			margin-left:-145px;
			left:50%;
			padding: 0 60px;

			@media(min-width: $breakpoint-xlarge) {
				width:340px;
				margin-left:-170px;
				padding: 0 80px;
			}

			&.nav-max-reached {
				position:relative;
				display:inline-block;
				width:100%;
				text-align: center;
				padding:0;
				margin-left:0;
				left:0;
				img {
					display: inline-block;
					max-width:176px;
				}
			}
		}

		#offcanvas-trigger {
			display:none;
		}

		&.mobile-header {
			position: relative;
			margin: 20px 0 0 0;

			nav#main-nav {
				display:none;
			}

			#offcanvas-trigger {
				display:block;
				position: absolute;
				font-size: 24px;
				width: 30px;
				height: 34px;
				top: 50%;
				margin-top: -24px;
				left: 15px;
			}
		}

		nav#main-nav {
			padding:20px 10px;
			line-height: 1;
			@include opacity(.0001);
			
			@media(min-width: $breakpoint-xlarge) {
				padding:20px 0 22px;
			}
			

			ul {
				padding: 0;
				margin: 0;
				list-style-type: none;
				display: inline-block;
    			width: 100%;

				li {
					float:left;

					&.pos-right:first-child {
						margin-left:100px;
					}

					&.shop-entry {

						a, span {
							color:$secondary;
						}
					}

					&.home-item {
						a {
							color:#75819c;
							i {
								margin-right: 0;
								font-size:.9em;
							}
						}
					}

					&.facebook-item {
						a {
							color:#3B5998;
						}
					}

					a, span {
						text-transform: uppercase;
						color:$primary;
						//font-weight:bold;
						font-size: 1.25em;
						cursor:pointer;
						@include ease(all 0.15s);

						&:hover {
							color:$secondary;
						}

						&.active {
							color:$secondary;
							position:relative;

							&:before {
								display:none !important;
							}

							&:after {
								position:absolute;
								content:"";
								width: 0;
								height: 0;
								border-left: 7px solid transparent;
								border-right: 7px solid transparent;
								border-bottom: 8px solid $secondary;
								top: 69px;
								left: 50%;
								margin-left: -7px;
							}
						}
					}

					span:not(.fontawesome-text) {
						position:relative;
					}

					ul {
						display:none;
						position:absolute;
						left:0;
						width:100%;
						top: 174px;
						background: #fff;
						z-index: 2;
						border-bottom: 2px solid $border-color-light;
						border-top: 1px solid $secondary;
						padding: 35px 15px;


						@media (min-width:$breakpoint-xlarge){
							padding: 35px 0;
						}

						.container {
							position:relative;

							.nav-info {
								position: absolute;
								right:0;
								width:300px;
								top:0;
							}
						}

						li {
							float:left;
							width:50%;
							border-left: 1px solid $border-color-light;
							@include ease(all 0.15s);
							padding-left:30px;

							&:hover {
								border-left:1px solid $secondary;
							}

							@media(min-width: $breakpoint-large) {
								width:33.33333%;
							}

							a, span {
								font-size:1em;
								font-weight: normal;
								text-transform: none;
								padding:8px 0;
								display: inline-block;
								width:100%;
								color:$primary;

								i {
									width:20px;
									text-align: center;
									font-size:.8em;
								}

								&:hover {
									color:$secondary;
								}
							}
							
							a[target="_blank"] {
								
								&:before {
									font-family: "FontAwesome";
									display: inline-block;
    								vertical-align: bottom;
									content:"\f08e";
									margin-right:10px;
								}
							}

						}
					}

					.nav-info {
						padding: 10px 22px;
						background: #EEF1F7;
						color:$primary;
						font-size:.9em;
						line-height:1.3;
						display:none;
					}
				}
			}
		}
	}
}