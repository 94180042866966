.ap-slider {
	margin-top:20px;
}

.ap-item {
	padding:0 50px;
	position: relative;
	@media(min-width:$breakpoint-xlarge) {
		padding:0 65px;
	}

	.image-wrap {
		float:left;
		width:100%;
		text-align: center;
		overflow:hidden;

		@media(min-width:$breakpoint-small) {
			width:120px;
		}

		@media(min-width:$breakpoint-xlarge) {
			width:150px;
		}

		img {
			display:inline-block;
			width:120px;
			height:120px;
			@include borderradius(100px);

			@media(min-width:$breakpoint-xlarge) {
				width:150px;
				height:150px;
			}
		}
	}

	.text-wrap {
		float:left;
		width:100%;
		text-align: center;

		@media(min-width:$breakpoint-small) {
			float:none;
			width:auto;
			text-align: left;
			padding-left:145px;
			padding-top:4px;
		}

		@media(min-width:$breakpoint-xlarge) {
			padding-left:170px;
			padding-top:8px;

		}

		header {
			margin-bottom:15px;

			* {
				margin:0;
			}

			h4 {
				color:#9c9c9c;
			}
		}

		span {
			color:#9c9c9c;
			display: inline-block;
			width:100%;
			@media(min-width:$breakpoint-large) {
				font-size:.9em;
			}

			i {
				width:20px;
				text-align: center;
				margin-right:10px;
			}
		}
	}
}