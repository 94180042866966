.bottom-article-nav {
	padding-top:30px;
	border-top:1px solid $border-color-light;

	.shop-info {
		padding:8px 14px;
		font-size:.85em;
		margin-right:10px;
		background:$grey;
		color:#fff;
		@include borderradius(100px);

		i {
			margin-right:6px;
		}
	}
}


table.article-listing {

	tbody {

		tr {
			cursor:pointer;

			@media(min-width:$breakpoint-large) {
				cursor:default;
			}

			td {
			
				&.bezeichnung {
					.hersteller {
						display: block;
						color:$lightgrey-dd;
					}
					.originalbezeichnung {
						display: block;
						color:$lightgrey-dd;
						font-size:.9em;
					}
					.badge {
						font-size: .9em;
						padding: 2px 4px;
						background-color: $secondary;
						color: #fff;
					}
				}

				&.bestand {

					.status {
						display:block;
						@include borderradius(100px);
						font-size:12px;
						padding:4px 12px;
						clear:both;
						background: #aaa;

						&.status1 {
							color:#fff;
							background: $green;
						}
						&.status2 {
							background: $lightgrey;
						}
					}
				}
			}
		}
	}
}