.sb-row-wrap {
	display: inline-block;
	width:100%;
	margin-bottom:10px;

	&.info {
		border: 1px solid $border-color-light;
		padding:8px;
	}

	.infobox {
		border-left:2px solid $border-color-light;
		padding:5px 10px;
		margin-bottom:8px;
	}

	&.error {
		border: 1px solid #FFDDDD;
		background:#FFF2F2;
		color:$secondary;

		.infobox {
			border-left: 2px solid #FFDDDD;
		}
	}

	&.success {
		border: 1px solid #BFE8C1;
		background:#EFFBF0;
		color:#448847;

		.infobox {
			border-left: 2px solid #BFE8C1;
		}
	}
}