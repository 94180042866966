.check {
	font-size:.85em;
	@include borderradius(5px);
	padding:13px 25px;
	background:$border-color-light;
	color:$primary;
	display: inline-block;
	width:100%;
	text-align: center;
	display:none;

	&.active {
		display:inline-block;
	}

	i:not(.fa-no-margin) {
		margin-right:8px;
	}
}