form[name=csvimport] {
	input[type=file] {
		margin:0;
		padding:0;
		border:0;
	}
}

form[name=formArticlePrintSheet], form[name=formArticleEnergyLabel] {
	.logobox {
		background-color:#fff;
		border:1px solid $border-color-light;
		color: #0f2455;
		font-size: 0.8em;
		font-weight: normal;
		height: 140px;
		padding: 15px 20px;
	}
}

.searchform-wrap {

	.searchform-inner {
		border:1px solid $border-color-light;
		background: $border-color-light-l;
		padding:20px;

		.filter-item {
			margin:8px 0;
		}
	}

	.uk-accordion-content {
		.searchform-inner {
			border-top:none;
		}
	}



	.dl-wrap {
		margin:0;
		padding:10px;
		border:1px solid $border-color-light;
		background: #fff;

		dt {
			font-size:.8em;
			font-weight:600;
			text-transform: uppercase;
			margin-top:10px;
			color:$lightgrey-dd;
		}

		dl {
			margin-top:0;
			margin-bottom:10px;
			font-size:1.2em;
			color:$primary;
		}
	}
}
#searchresults {
	select[name=pagesize] {
		height: auto;
		color: $primary;
		padding: 4px 25px 4px 4px;
		font-size: 100%;
	}
	
	.tiledcontent {

		.tile {
			padding: 20px 0;
			border-bottom: 2px solid $lightgrey-l;
			
			.image {
				img {
					margin: 20px auto;
					width: auto;
					height: 200px;
					object-fit: contain;
				}
			}
			
			.bezeichnung a {
				color:$lightgrey-dd;
				font-size:1.2em;
			}
			.hersteller {
				color:$lightgrey-dd;
				font-size:.8em;
				padding-bottom: 15px;
			}
			.bestand {
				&.bestand {
					.status {
						@include borderradius(100px);
						font-size:12px;
						padding:4px 12px;
						vertical-align: middle;
						clear:both;
						background: #aaa;

						&.status1 {
							color:#fff;
							background: $green;
						}
						&.status2 {
							background: $lightgrey;
						}
					}
				}
			}
			.artnr {
				color:$lightgrey-dd;
				font-size:.8em;
			}
			.nettopreis {
				padding: 15px 0;
			}
			.badge {
				font-size: .9em;
				padding: 2px 4px;
				background-color: $secondary;
				color: #fff;
			}
		}
	}

	.pagernavigation {
		margin: 0;
		padding: 8px 0;
		background-color: $lightgrey-l;
		
		div.text-center {
			padding-top: 6px;
		}
	}
}

.bargainlist {
	select[name=pagesize] {
		height: auto;
		color: $primary;
		padding: 4px 25px 4px 4px;
		font-size: 100%;
	}
	
	.pagernavigation {
		margin: 10px 0;
		padding: 8px 0;
		background-color: $lightgrey-l;
		
		div.text-center {
			padding-top: 6px;
		}
	}
}

#last_viewed {
	.smalltile {
		.image {
			img {
				width: auto;
				height: 100px;
				object-fit: contain;
			}
		}
		.artnr {
			color:$lightgrey-dd;
			font-size:.6em;
		}
		.bezeichnung {
			color:$lightgrey-dd;
			font-size:0.8em;
			line-height: 1.1em;
			a {
				color: $primary;
			}
		}
		.hersteller {
			color:$lightgrey-dd;
			font-size:.6em;
		}
	}
}
