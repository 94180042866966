.tag-item {
	padding: 3px 6px 1px 6px;
	font-size: 13px !important;
	display: inline-block;
	text-transform: uppercase;
	margin-bottom: 6px !important;
}

.tag-listing {
	list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 5px;
    left: 0;

    li {
    	//display: inline-block;

    	.tag-item {
    		margin-bottom:2px !important;
    		margin-right:2px !important;
    		font-size: 14px !important;
    	}
    }
}