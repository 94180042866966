.standort-listing {
	
}

.standort-item {
	margin:20px 0;

	@media(min-width:$breakpoint-large){
		margin:30px 0;
	}
	
	.contactinfos {
		color: #3a3a3a;

		i {
			&.fa {
				width: 30px;
			}	
		}
	}

	.openingtimes {

		a {
			color:#848484;
		}

		&.na {
			color:#bfbfbf;
		}

		.openingtimes-contents {
			display: none !important;
		}
	}
}