// Default Einstellungen
@import 'uikit/scss/uikit-mixins';
@import 'uikit/scss/uikit-variables';


// Partials

// Core
@import 'uikit/scss/core/grid';
@import 'uikit/scss/core/utility';
@import 'uikit/scss/core/modal';
@import 'uikit/scss/core/dropdown';
@import 'uikit/scss/core/flex';

// Components
@import 'uikit/scss/components/tooltip';
@import 'uikit/scss/components/datepicker';



// Overwrites

.uk-container {
  margin: 0 auto;
}

.uk-datepicker-table a.uk-active {
  background: $secondary;
}
