article {
	margin:$spacer-phone-large 0;
	padding-left:10px;
	padding-right:10px;

	@media(min-width:$breakpoint-small) {
		margin:$spacer-tablet-large 0;
	}

	@media(min-width:$breakpoint-large) {
		margin:$spacer-large 0;
	}

	@media(min-width:$breakpoint-xlarge) {
		padding-left:0;
		padding-right:0;
	}

	header {
		display:inline-block;
		width:100%;
	}

	&.welcome-block {
		margin-bottom:0;
		padding-bottom:35px;
		@media(min-width:$breakpoint-xlarge) {
			padding-top:35px;
		}

		.welcome-inner {
			display: inline-block;
			width:100%;
		}

		header {
			float:left;
			float: left;
			width:auto;
		    margin-right: 40px;
		    padding-right: 40px;
		    border-right: 1px solid $border-color-light;

			* { margin:0; }
		}

		.meta {
			padding: 19px 0;
		}
	}
	&.welcome-block + article {
		margin-top:0;
	}

	/*
	&.text-block + .text-block,
	&.text-block + .list-wrap,
	&.list-wrap + .text-block,
	&.list-wrap + .list-wrap {
		margin-top:25px;
	}
	*/

	&.shop-wrap {

		header {
			h1 {
				float:left;
				.resultinfo {
					display:none;
				}
			}
			a.btn {
				margin-top:1.6em;
				
				&.edit-search {
					margin-left: 2em;
					}
			}
		}
	}

	&.marketing-text {

		header {
			h2+h2 {
				margin-top:0;
			}
		}
	}


	&.col-text-2 p {
		/*
	    -webkit-column-count: 2;
	    -webkit-column-gap: 10px;
	    -moz-column-count: 2;
	    -moz-column-gap: 10px;
	    column-count: 2;
	    column-gap: 10px;
	    */
	    @include twoCol(2, 30px);
	}

	&.col-text-3 p {
	    @include twoCol(3, 30px);
	}



	&.text-block {

		ul {
			list-style-type: none;
			padding:0;

			li {
				position: relative;
				padding-left:30px;

				&:before {
					font-family:"FontAwesome";
					content:"\f101";
					color:$secondary;
					position: absolute;
					left:10px;
					top:0;
					font-size: .85em;
				}
			}
		}
	}

	&.text-block-bild {

		.text-wrap {
			:first-child {
				margin-top:0 !important;
			}
		}
	}




	&:first-child {
		margin-top:28px;
	}
}


aside {
	//border-left:1px solid $border-color-light;
	margin-top: 32px;
    //padding: 10px 0 10px 30px;
    background: $border-color-light;

	article {
		margin:20px 0;

		&:first-child {
			margin-top:0;
		}

		&:last-child {
			margin-bottom:0;
		}
	}
}