.buchungsformular, .map-wrap {
    margin-top: 30px;
    padding-top: 30px;
    display: inline-block;
    width: 100%;
    border-top: 1px solid $border-color-light;
}

.map-wrap {
	padding-top: 38px;
}


.single-event {

	img {
		height: 50px;
		width: auto;
	}
	
	header {
		border-bottom: 1px solid $border-color-light;		
		margin-bottom:15px;
	}
}


.em-booking {

	.em-booking-form-details {
		width:100%;
		padding:0;
		border:1px solid $border-color-light;

		p {
			width:100%;
			float:left;
			clear:none;

			@media (min-width:$breakpoint-small) {
				width:48%;
				margin:15px 0 15px 1.3333%;
			}

			@media (min-width:$breakpoint-large) {
				width:29%;
				margin:15px 0 15px 3.3333%;
			}

			input, select, label, textarea {
				width:100%;
				
				&.readonly {
					background-color: $lightgrey-l;
				}
			}


			textarea {
				min-height: 200px;
			}


			&.input-field-booking_comment {
				clear: both;
				width: 97.33333%;

				@media (min-width:$breakpoint-large) {
					width: 93.666666%;
				}
			}

			&.input-field-vam, &.input-field-kundenname {
				display: none !important;
			}
		}


		.em-booking-buttons {
			float:left;
			width:97.333%;
			margin:15px 1.3333%;
			clear: both;
			text-align: right;


			@media (min-width:$breakpoint-large) {
				margin:15px 3.3333%;
				width:93.666%;
			}

			input[type="submit"] {
				width:100%;
				max-width: 320px;
				background: $primary;
				color: #fff;
				cursor: pointer;
				@include borderradius($button-border-radius);
				@include ease($button-easing);

				&:hover { background: $primary-l; color:#fff; }
				&:active { background: $primary-d; color:#fff; }
				&:focus { background: $primary; color:#fff; }
			}
		}
	}
}



tr.em-attendee-details { 

	&:hover {
		background: transparent;
	}

	td {
		padding:0;		
	}
}

.em-attendee-fieldset {
	clear: both;
	width:100%;
	background: $border-color-light;
	display: inline-block;
	counter-reset: attendee;

	&:before {
		content:"Teilnehmer:";
		font-family: $font-secondary;
		margin-left:3.3333%;
		margin-top:15px;
		float:left;
		font-size:1.4em;
		font-weight:bold;
		color:$secondary;
	}

	.em-attendee-fields {
		clear:both;
		padding-left: 30px;
		position: relative;
		display: inline-block;
		width: 100%;

		p {			
			float:left;
			clear: none;
			width:45.333333%;
			margin:15px 0 15px 3.33333%;

			label {
				display: none;
			}
		}

		&:first-child {

			p {
				
				label {
					display: block;
				}
			}
		}

		&:before {
			counter-increment: attendee;
			content: counter(attendee) ".";
			position: absolute;
			left:40px;
			bottom:25px;
			color:$secondary;
			font-size:20px;
		}
	}
}

.location-map {
	float: right;
	margin-left: 25px;
	margin-bottom: 25px;
}


.em-booking-gateway-form {
	font-family:$font-secondary;
	font-size:1.3em;
	float:left;
	width:100%;
	text-align: center;

	@media (min-width:$breakpoint-small) and (max-width:$breakpoint-large) {
		width:49%;
		margin-left:2%;
	}

	@media (min-width:$breakpoint-large) {
		width:32%;
		margin-left:2%;
	}

	* {
		display: inline-block;
	}
}

table.em-tickets {

	tr:not(.em-ticket) {

		th {
			font-family:OfficinaSansITCTTBold, sans-serif;
			font-size:.9em;
			padding: 15px 10px;
			text-transform: uppercase;
			border-bottom:1px solid $border-color-light;
			border-top:1px solid $border-color-light;
			text-align: left;

			&:first-child {
				border-left:1px solid $border-color-light;
			}
			&:last-child {
				border-right:1px solid $border-color-light;
			}
		}
	}
}