$accordion-title-margin-bottom:                  15px !default;
$accordion-title-padding-vertical:               5px !default;
$accordion-title-padding-horizontal:             15px !default;
$accordion-title-font-size:                      18px !default;
$accordion-title-line-height:                    24px !default;
$accordion-title-background:                     #eee !default;
$accordion-content-padding-horizontal:           $accordion-title-padding-horizontal !default;
$accordion-content-padding-bottom:               $accordion-title-padding-horizontal !default;
$nav-autocomplete-color:                         #444 !default;
$nav-autocomplete-active-background:             #00a8e6 !default;
$nav-autocomplete-active-color:                  #fff !default;
$nav-autocomplete-header-color:                  #999 !default;
$nav-autocomplete-divider-border:                #ddd !default;
$nav-autocomplete-divider-border-width:          1px !default;
$datepicker-z-index:                             1050 !default;
$datepicker-animation:                           uk-fade !default;
$datepicker-nav-height:                          20px !default;
$datepicker-nav-margin-bottom:                   15px !default;
$datepicker-nav-color:                           #444 !default;
$datepicker-nav-hover-color:                     #444 !default;
$datepicker-previous-icon:                       "\f053" !default;
$datepicker-next-icon:                           "\f054" !default;
$datepicker-table-width:                         26px !default;
$datepicker-table-height:                        24px !default;
$datepicker-table-color:                         #444 !default;
$datepicker-table-hover-background:              #ddd !default;
$datepicker-table-hover-color:                   #444 !default;
$datepicker-table-onclick-background:            #ccc !default;
$datepicker-table-onclick-color:                 #444 !default;
$datepicker-table-active-background:             #00a8e6 !default;
$datepicker-table-active-color:                  #fff !default;
$datepicker-table-muted-color:                   #999 !default;
$dotnav-margin-horizontal:                       15px !default;
$dotnav-margin-vertical:                         $dotnav-margin-horizontal !default;
$dotnav-width:                                   20px !default;
$dotnav-height:                                  $dotnav-width !default;
$dotnav-background:                              rgba(50,50,50,0.1) !default;
$dotnav-hover-background:                        rgba(50,50,50,0.4) !default;
$dotnav-onclick-background:                      rgba(50,50,50,0.6) !default;
$dotnav-active-background:                       rgba(50,50,50,0.4) !default;
$dotnav-contrast-background:                     rgba(255,255,255,0.4) !default;
$dotnav-contrast-hover-background:               rgba(255,255,255,0.7) !default;
$dotnav-contrast-onclick-background:             rgba(255,255,255,0.9) !default;
$dotnav-contrast-active-background:              rgba(255,255,255,0.9) !default;
$form-advanced-size:                           14px !default;
$form-advanced-border:                         #aaa !default;
$form-advanced-border-width:                   1px !default;
$form-advanced-margin-top:                     -4px !default;
$form-advanced-color:                          transparent !default;
$form-advanced-checked-color:                  #00a8e6 !default;
$form-advanced-radio-size:                     8px !default;
$form-advanced-checkbox-icon:                  "\f00c" !default;
$form-advanced-checkbox-indeterminate-icon:    "\f068" !default;
$form-advanced-checkbox-font-size:             12px !default;
$form-advanced-disabled-border:                #ddd !default;
$form-advanced-disabled-color:                 #aaa !default;
$form-password-font-size:                        13px !default;
$form-password-line-height:                      $form-password-font-size !default;
$form-password-color:                            #999 !default;
$form-password-hover-color:                      #999 !default;
$form-password-padding:                          50px !default;
$htmleditor-navbar-background:                   #eee !default;
$htmleditor-navbar-nav-height:                   40px !default;
$htmleditor-navbar-nav-padding-horizontal:       15px !default;
$htmleditor-navbar-nav-line-height:              $htmleditor-navbar-nav-height !default;
$htmleditor-navbar-nav-color:                    #444 !default;
$htmleditor-navbar-nav-hover-background:         #f5f5f5 !default;
$htmleditor-navbar-nav-hover-color:              #444 !default;
$htmleditor-navbar-nav-onclick-background:       #ddd !default;
$htmleditor-navbar-nav-onclick-color:            #444 !default;
$htmleditor-navbar-nav-active-background:        #f5f5f5 !default;
$htmleditor-navbar-nav-active-color:             #444 !default;
$htmleditor-content-border:                      #ddd !default;
$htmleditor-content-background:                  #fff !default;
$htmleditor-content-padding:                     20px !default;
$htmleditor-content-split-border:                #eee !default;
$htmleditor-fullscreen-z-index:                  990 !default;
$htmleditor-fullscreen-content-top:              $htmleditor-navbar-nav-height !default;
$htmleditor-fullscreen-icon-resize:              "\f066" !default;
$nestable-padding-left:                          40px !default;
$nestable-item-margin:                           10px !default;
$nestable-dragged-z-index:                       1050 !default;
$nestable-placeholder-border:                    #ddd !default;
$nestable-empty-height:                          30px !default;
$nestable-toggle-icon:                           "\f147" !default;
$nestable-toggle-collapsed-icon:                 "\f196" !default;
$nestable-panel-padding:                         5px !default;
$nestable-panel-background:                      #f5f5f5 !default;
$notify-position:                                10px !default;
$notify-z-index:                                 1040 !default;
$notify-width:                                   350px !default;
$notify-message-margin-bottom:                   10px !default;
$notify-message-padding:                         15px !default;
$notify-message-background:                      #444 !default;
$notify-message-color:                           #fff !default;
$notify-message-font-size:                       16px !default;
$notify-message-line-height:                     22px !default;
$notify-message-primary-background:              #ebf7fd !default;
$notify-message-primary-color:                   #2d7091 !default;
$notify-message-success-background:              #f2fae3 !default;
$notify-message-success-color:                   #659f13 !default;
$notify-message-warning-background:              #fffceb !default;
$notify-message-warning-color:                   #e28327 !default;
$notify-message-danger-background:               #fff1f0 !default;
$notify-message-danger-color:                    #d85030 !default;
$placeholder-margin-vertical:                    15px !default;
$placeholder-padding:                            20px !default;
$placeholder-border:                             #ddd !default;
$placeholder-background:                         #fafafa !default;
$placeholder-color:                              #444 !default;
$placeholder-large-padding-vertical:             80px !default;
$progress-height:                                20px !default;
$progress-mini-height:                           6px !default;
$progress-small-height:                          12px !default;
$progress-margin-vertical:                       15px !default;
$progress-background:                            #eee !default;
$progress-bar-background:                        #00a8e6 !default;
$progress-bar-font-size:                         12px !default;
$progress-bar-color:                             #fff !default;
$progress-bar-success-background:                #8cc14c !default;
$progress-bar-warning-background:                #faa732 !default;
$progress-bar-danger-background:                 #da314b !default;
$search-width:                                   120px !default;
$search-focus-width:                             180px !default;
$search-height:                                  30px !default;
$search-padding:                                 30px !default;
$search-border:                                  rgba(0,0,0,0) !default;
$search-border-width:                            1px !default;
$search-background:                              rgba(0,0,0,0) !default;
$search-color:                                   #444 !default;
$search-placeholder-color:                       #999 !default;
$search-icon:                                    "\f002" !default;
$search-icon-size:                               14px !default;
$search-icon-color:                              rgba(0,0,0,0.2) !default;
$dropdown-search-width:                          300px !default;
$dropdown-search-margin-top:                     0 !default;
$dropdown-search-background:                     #f5f5f5 !default;
$dropdown-search-color:                          #444 !default;
$dropdown-search-animation:                      uk-slide-top-fixed !default;
$dropdown-search-navbar-margin-top:              5px !default;
$dropdown-search-navbar-margin-right:            -15px !default;
$nav-search-color:                               #444 !default;
$nav-search-active-background:                   #00a8e6 !default;
$nav-search-active-color:                        #fff !default;
$nav-search-header-color:                        #999 !default;
$nav-search-divider-border:                      #ddd !default;
$nav-search-divider-border-width:                1px !default;
$nav-search-nested-color:                        #07D !default;
$nav-search-nested-hover-color:                  #059 !default;
$offcanvas-search-margin:                        20px 15px !default;
$offcanvas-search-background:                    #1a1a1a !default;
$offcanvas-search-border:                        rgba(0,0,0,0) !default;
$offcanvas-search-color:                         #ccc !default;
$offcanvas-search-placeholder-color:             #777 !default;
$offcanvas-search-icon-color:                    #777 !default;
$slidenav-width:                                 60px !default;
$slidenav-height:                                $slidenav-width !default;
$slidenav-color:                                 rgba(50,50,50,0.4) !default;
$slidenav-font-size:                             60px !default;
$slidenav-line-height:                           $slidenav-height !default;
$slidenav-previous-icon:                         "\f104" !default;
$slidenav-next-icon:                             "\f105" !default;
$slidenav-hover-color:                           rgba(50,50,50,0.7) !default;
$slidenav-active-color:                          rgba(50,50,50,0.9) !default;
$slidenav-position-previous:                     20px !default;
$slidenav-position-next:                         $slidenav-position-previous !default;
$slidenav-contrast-color:                        rgba(255,255,255,0.5) !default;
$slidenav-contrast-hover-color:                  rgba(255,255,255,0.7) !default;
$slidenav-contrast-active-color:                 rgba(255,255,255,0.9) !default;
$sortable-dragged-z-index:                       1050 !default;
$sortable-placeholder-opacity:                   0 !default;
$sortable-empty-height:                          30px !default;
$sticky-z-index:                                 980 !default;
$sticky-animation-duration:                      0.2s !default;
$sticky-reverse-animation-duration:              0.2s !default;
$tooltip-z-index:                                1030 !default;
$tooltip-max-width:                              200px !default;
$tooltip-padding-vertical:                       5px !default;
$tooltip-padding-horizontal:                     8px !default;
$tooltip-background:                             #333 !default;
$tooltip-triangle-size:                          5px !default;
$tooltip-color:                                  rgba(255,255,255,0.7) !default;
$tooltip-font-size:                              12px !default;
$tooltip-line-height:                            18px !default;
$tooltip-offset-horizontal:                      10px !default;
$uk-upload-dragover-box-shadow:                  rgba(100,100,100,0.3) !default;
$uk-upload-dragover-box-shadow-blur:             20px !default;
$alert-margin-vertical:                          15px !default;
$alert-padding:                                  10px !default;
$alert-background:                               #ebf7fd !default;
$alert-color:                                    #2d7091 !default;
$alert-success-background:                       #f2fae3 !default;
$alert-success-color:                            #659f13 !default;
$alert-warning-background:                       #fffceb !default;
$alert-warning-color:                            #e28327 !default;
$alert-danger-background:                        #fff1f0 !default;
$alert-danger-color:                             #d85030 !default;
$alert-large-padding:                            20px !default;
$article-margin-top:                             25px !default;
$article-title-font-size:                        36px !default;
$article-title-line-height:                      42px !default;
$article-title-font-weight:                      normal !default;
$article-title-text-transform:                   none !default;
$article-meta-font-size:                         12px !default;
$article-meta-line-height:                       18px !default;
$article-meta-color:                             #999 !default;
$article-lead-color:                             #444 !default;
$article-lead-font-size:                         18px !default;
$article-lead-line-height:                       24px !default;
$article-lead-font-weight:                       normal !default;
$article-divider-margin:                         25px !default;
$article-divider-border:                         #ddd !default;
$badge-background:                               #00a8e6 !default;
$badge-padding-horizontal:                       5px !default;
$badge-font-size:                                10px !default;
$badge-font-weight:                              bold !default;
$badge-line-height:                              14px !default;
$badge-color:                                    #fff !default;
$badge-text-transform:                           none !default;
$badge-hover-color:                              #fff !default;
$badge-notification-font-size:                   12px !default;
$badge-notification-line-height:                 18px !default;
$badge-success-background:                       #8cc14c !default;
$badge-warning-background:                       #faa732 !default;
$badge-danger-background:                        #da314b !default;
$base-body-background:                           #fff !default;
$base-body-font-family:                          "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-body-font-weight:                          normal !default;
$base-body-font-size:                            14px !default;
$base-body-line-height:                          20px !default;
$base-body-color:                                #444 !default;
$base-link-color:                                #07D !default;
$base-link-text-decoration:                      none !default;
$base-link-hover-color:                          #059 !default;
$base-link-hover-text-decoration:                underline !default;
$base-code-color:                                #D05 !default;
$base-code-font-size:                            12px !default;
$base-code-font-family:                          Consolas, monospace, serif !default;
$base-em-color:                                  #D05 !default;
$base-ins-background:                            #ffa !default;
$base-ins-color:                                 #444 !default;
$base-mark-background:                           #ffa !default;
$base-mark-color:                                #444 !default;
$base-quote-font-style:                          italic !default;
$base-margin-vertical:                           15px !default;
$base-heading-font-family:                       "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-heading-font-weight:                       normal !default;
$base-heading-color:                             #444 !default;
$base-heading-text-transform:                    none !default;
$base-heading-margin-top:                        25px !default;
$base-h1-font-size:                              36px !default;
$base-h1-line-height:                            42px !default;
$base-h2-font-size:                              24px !default;
$base-h2-line-height:                            30px !default;
$base-h3-font-size:                              18px !default;
$base-h3-line-height:                            24px !default;
$base-h4-font-size:                              16px !default;
$base-h4-line-height:                            22px !default;
$base-h5-font-size:                              14px !default;
$base-h5-line-height:                            20px !default;
$base-h6-font-size:                              12px !default;
$base-h6-line-height:                            18px !default;
$base-list-padding-left:                         30px !default;
$base-hr-margin-vertical:                        $base-margin-vertical !default;
$base-hr-border:                                 #ddd !default;
$base-hr-border-width:                           1px !default;
$base-blockquote-padding-left:                   15px !default;
$base-blockquote-border:                         #ddd !default;
$base-blockquote-border-width:                   5px !default;
$base-blockquote-font-size:                      16px !default;
$base-blockquote-line-height:                    22px !default;
$base-blockquote-font-style:                     italic !default;
$base-pre-padding:                               10px !default;
$base-pre-background:                            #f5f5f5 !default;
$base-pre-color:                                 #444 !default;
$base-pre-font-size:                             12px !default;
$base-pre-line-height:                           18px !default;
$base-pre-font-family:                           $base-code-font-family !default;
$base-selection-background:                      #39f !default;
$base-selection-color:                           #fff !default;
$block-padding-vertical:                         20px !default;
$block-padding-vertical-large:                   50px !default;
$block-large-padding-vertical:                   $block-padding-vertical !default;
$block-large-padding-vertical-medium:            $block-padding-vertical-large !default;
$block-large-padding-vertical-large:             100px !default;
$block-default-background:                       #fff !default;
$block-muted-background:                         #f9f9f9 !default;
$block-primary-background:                       #00a8e6 !default;
$block-secondary-background:                     #222 !default;
$breadcrumb-font-size:                           1rem !default;
$breadcrumb-divider:                             "/" !default;
$breadcrumb-divider-margin:                      8px !default;
$breadcrumb-disabled-color:                      #999 !default;
$button-height:                                  30px !default;
$button-mini-height:                             20px !default;
$button-small-height:                            25px !default;
$button-large-height:                            40px !default;
$button-line-height:                             30px !default;
$button-mini-line-height:                        20px !default;
$button-small-line-height:                       25px !default;
$button-large-line-height:                       40px !default;
$button-mini-font-size:                          11px !default;
$button-small-font-size:                         12px !default;
$button-large-font-size:                         16px !default;
$button-padding-horizontal:                      12px !default;
$button-mini-padding-horizontal:                 6px !default;
$button-small-padding-horizontal:                10px !default;
$button-large-padding-horizontal:                15px !default;
$button-font-size:                               1rem !default;
$button-background:                              #eee !default;
$button-color:                                   #444 !default;
$button-hover-background:                        #f5f5f5 !default;
$button-hover-color:                             #444 !default;
$button-active-background:                       #ddd !default;
$button-active-color:                            #444 !default;
$button-primary-background:                      #00a8e6 !default;
$button-primary-color:                           #fff !default;
$button-primary-hover-background:                #35b3ee !default;
$button-primary-hover-color:                     #fff !default;
$button-primary-active-background:               #0091ca !default;
$button-primary-active-color:                    #fff !default;
$button-success-background:                      #8cc14c !default;
$button-success-color:                           #fff !default;
$button-success-hover-background:                #8ec73b !default;
$button-success-hover-color:                     #fff !default;
$button-success-active-background:               #72ae41 !default;
$button-success-active-color:                    #fff !default;
$button-danger-background:                       #da314b !default;
$button-danger-color:                            #fff !default;
$button-danger-hover-background:                 #e4354f !default;
$button-danger-hover-color:                      #fff !default;
$button-danger-active-background:                #c91032 !default;
$button-danger-active-color:                     #fff !default;
$button-disabled-background:                     #f5f5f5 !default;
$button-disabled-color:                          #999 !default;
$button-link-color:                              #07D !default;
$button-link-hover-color:                        #059 !default;
$button-link-hover-text-decoration:              underline !default;
$button-link-disabled-color:                     #999 !default;
$close-size:                                     20px !default;
$close-alt-padding:                              2px !default;
$close-alt-background:                           #eee !default;
$comment-header-margin-bottom:                   15px !default;
$comment-avatar-margin-right:                    15px !default;
$comment-title-margin-top:                       5px !default;
$comment-title-font-size:                        16px !default;
$comment-title-line-height:                      22px !default;
$comment-meta-margin-top:                        2px !default;
$comment-meta-color:                             #999 !default;
$comment-meta-font-size:                         11px !default;
$comment-meta-line-height:                       16px !default;
$comment-list-margin-top:                        15px !default;
$comment-list-padding-left:                      100px !default;
$column-gutter:                                    25px !default;
$contrast-color:                                 #fff !default;
$contrast-inverted-color:                        #444 !default;
$contrast-base-color:                            $contrast-color !default;
$contrast-base-link-color:                       fade($contrast-color, 70%) !default;
$contrast-base-link-text-decoration:             none !default;
$contrast-base-link-hover-color:                 $contrast-color !default;
$contrast-base-link-hover-text-decoration:       underline !default;
$contrast-base-code-color:                       $contrast-color !default;
$contrast-base-em-color:                         $contrast-color !default;
$contrast-base-heading-color:                    $contrast-color !default;
$contrast-base-hr-border:                        fade($contrast-color, 20%) !default;
$contrast-nav-side-color:                        $contrast-color !default;
$contrast-nav-side-hover-background:             fade($contrast-color, 10%) !default;
$contrast-nav-side-hover-color:                  $contrast-color !default;
$contrast-nav-side-active-background:            $contrast-color !default;
$contrast-nav-side-active-color:                 $contrast-inverted-color !default;
$contrast-nav-side-header-color:                 $contrast-color !default;
$contrast-nav-side-divider-border:               fade($contrast-color, 20%) !default;
$contrast-nav-side-nested-color:                 fade($contrast-color, 70%) !default;
$contrast-nav-side-nested-hover-color:           $contrast-color !default;
$contrast-subnav-color:                          fade($contrast-color, 70%) !default;
$contrast-subnav-hover-color:                    $contrast-color !default;
$contrast-subnav-hover-text-decoration:          none !default;
$contrast-subnav-active-color:                   $contrast-color !default;
$contrast-subnav-line-border:                    fade($contrast-color, 20%) !default;
$contrast-subnav-pill-hover-background:          fade($contrast-color, 70%) !default;
$contrast-subnav-pill-hover-color:               $contrast-inverted-color !default;
$contrast-subnav-pill-active-background:         $contrast-color !default;
$contrast-subnav-pill-active-color:              $contrast-inverted-color !default;
$contrast-tab-border:                            fade($contrast-color, 20%) !default;
$contrast-tab-color:                             fade($contrast-color, 70%) !default;
$contrast-tab-hover-border:                      fade($contrast-color, 70%) !default;
$contrast-tab-hover-background:                  fade($contrast-color, 70%) !default;
$contrast-tab-hover-color:                       $contrast-inverted-color !default;
$contrast-tab-active-border:                     fade($contrast-color, 20%) !default;
$contrast-tab-active-background:                 $contrast-color !default;
$contrast-tab-active-color:                      $contrast-inverted-color !default;
$contrast-list-line-border:                      fade($contrast-color, 20%) !default;
$contrast-form-border:                           fade($contrast-color, 80%) !default;
$contrast-form-background:                       fade($contrast-color, 80%) !default;
$contrast-form-color:                            $contrast-inverted-color !default;
$contrast-form-focus-border:                     $contrast-color !default;
$contrast-form-focus-background:                 $contrast-color !default;
$contrast-form-focus-color:                      $contrast-inverted-color !default;
$contrast-form-placeholder-color:                fade($contrast-inverted-color, 70%) !default;
$contrast-button-background:                     $contrast-color !default;
$contrast-button-color:                          $contrast-inverted-color !default;
$contrast-button-hover-background:               fade($contrast-color, 80%) !default;
$contrast-button-hover-color:                    $contrast-inverted-color !default;
$contrast-button-active-background:              fade($contrast-color, 70%) !default;
$contrast-button-active-color:                   $contrast-inverted-color !default;
$contrast-button-primary-background:             #00a8e6 !default;
$contrast-button-primary-color:                  $contrast-color !default;
$contrast-button-primary-hover-background:       #35b3ee !default;
$contrast-button-primary-hover-color:            $contrast-color !default;
$contrast-button-primary-active-background:      #0091ca !default;
$contrast-button-primary-active-color:           $contrast-color !default;
$contrast-icon-hover-color:                      fade($contrast-color, 70%) !default;
$contrast-icon-hover-hover-color:                $contrast-color !default;
$contrast-icon-button-background:                $contrast-color !default;
$contrast-icon-button-color:                     $contrast-inverted-color !default;
$contrast-icon-button-hover-background:          fade($contrast-color, 80%) !default;
$contrast-icon-button-hover-color:               $contrast-inverted-color !default;
$contrast-icon-button-active-background:         fade($contrast-color, 70%) !default;
$contrast-icon-button-active-color:              $contrast-inverted-color !default;
$contrast-text-muted-color:                      fade($contrast-color, 60%) !default;
$contrast-text-primary-color:                    #2d7091 !default;
$description-list-horizontal-width:              160px !default;
$description-list-horizontal-margin-left:        180px !default;
$description-list-line-margin-top:               5px !default;
$description-list-line-border-width:             1px !default;
$description-list-line-border:                   #ddd !default;
$description-list-line-color:                    #999 !default;
$description-list-line-font-weight:              normal !default;
$dropdown-z-index:                               970 !default;
$dropdown-width:                                 200px !default;
$dropdown-margin-top:                            5px !default;
$dropdown-padding:                               15px !default;
$dropdown-background:                            #f5f5f5 !default;
$dropdown-color:                                 #444 !default;
$dropdown-font-size:                             1rem !default;
$dropdown-animation:                             uk-fade !default;
$dropdown-divider-border-width:                  1px !default;
$dropdown-divider-border:                        #ddd !default;
$dropdown-small-padding:                         5px !default;
$dropdown-navbar-margin:                         0 !default;
$dropdown-navbar-background:                     #f5f5f5 !default;
$dropdown-navbar-color:                          #444 !default;
$dropdown-navbar-animation:                      uk-slide-top-fixed !default;
$dropdown-scrollable-height:                     200px !default;
$grid-gutter-horizontal:                         25px !default;
$grid-gutter-vertical:                           25px !default;
$grid-gutter-large-horizontal:                   35px !default;
$grid-gutter-large-vertical:                     35px !default;
$grid-gutter-xlarge-horizontal:                  50px !default;
$grid-gutter-xlarge-vertical:                    50px !default;
$grid-gutter-small-horizontal:                   10px !default;
$grid-gutter-small-vertical:                     10px !default;
$grid-divider-border:                            #ddd !default;
$grid-divider-border-width:                      1px !default;
$icon-font-path:                                 "../fonts" !default;
$icon-small-font-size:                           150% !default;
$icon-medium-font-size:                          200% !default;
$icon-large-font-size:                           250% !default;
$icon-small-vertical-align:                      -10% !default;
$icon-medium-vertical-align:                     -16% !default;
$icon-large-vertical-align:                      -22% !default;
$icon-hover-color:                               #999 !default;
$icon-hover-hover-color:                         #444 !default;
$icon-button-width:                              35px !default;
$icon-button-height:                             $icon-button-width !default;
$icon-button-border-radius:                      100% !default;
$icon-button-background:                         #eee !default;
$icon-button-font-size:                          round(($icon-button-width * 0.5)) !default;
$icon-button-color:                              #444 !default;
$icon-button-hover-background:                   #f5f5f5 !default;
$icon-button-hover-color:                        #444 !default;
$icon-button-active-background:                  #ddd !default;
$icon-button-active-color:                       #444 !default;
$form-height:                                    30px !default;
$form-padding:                                   4px 6px !default;
$form-border:                                    #ddd !default;
$form-border-width:                              1px !default;
$form-background:                                #fff !default;
$form-color:                                     #444 !default;
$form-focus-border:                              #99baca !default;
$form-focus-background:                          #f5fbfe !default;
$form-focus-color:                               #444 !default;
$form-disabled-border:                           #ddd !default;
$form-disabled-background:                       #f5f5f5 !default;
$form-disabled-color:                            #999 !default;
$form-placeholder-color:                         #999 !default;
$form-gutter:                                    15px !default;
$form-legend-border:                             #ddd !default;
$form-legend-border-width:                       1px !default;
$form-legend-font-size:                          18px !default;
$form-legend-line-height:                        30px !default;
$form-small-height:                              25px !default;
$form-large-height:                              40px !default;
$form-small-padding:                             3px 3px !default;
$form-large-padding:                             8px 6px !default;
$form-small-font-size:                           12px !default;
$form-large-font-size:                           16px !default;
$form-danger-border:                             #dc8d99 !default;
$form-danger-background:                         #fff7f8 !default;
$form-danger-color:                              #d85030 !default;
$form-success-border:                            #8ec73b !default;
$form-success-background:                        #fafff2 !default;
$form-success-color:                             #659f13 !default;
$form-blank-border:                              #ddd !default;
$form-blank-border-style:                        dashed !default;
$form-mini-width:                                40px !default;
$form-small-width:                               130px !default;
$form-medium-width:                              200px !default;
$form-large-width:                               500px !default;
$form-help-inline-margin-left:                   10px !default;
$form-help-block-margin-top:                     5px !default;
$form-controls-condensed-margin-vertical:        5px !default;
$form-stacked-margin-bottom:                     5px !default;
$form-stacked-font-weight:                       bold !default;
$form-horizontal-label-width:                    200px !default;
$form-horizontal-label-margin-top:               5px !default;
$form-horizontal-controls-margin-left:           215px !default;
$form-horizontal-controls-text-padding-top:      5px !default;
$form-icon-width:                                30px !default;
$form-icon-font-size:                            14px !default;
$form-icon-color:                                #999 !default;
$nav-padding-vertical:                           5px !default;
$nav-padding-horizontal:                         15px !default;
$nav-header-padding-vertical:                    $nav-padding-vertical !default;
$nav-header-padding-horizontal:                  $nav-padding-horizontal !default;
$nav-header-font-size:                           12px !default;
$nav-header-font-weight:                         bold !default;
$nav-header-text-transform:                      uppercase !default;
$nav-header-margin-top:                          15px !default;
$nav-divider-margin-vertical:                    9px !default;
$nav-divider-margin-horizontal:                  $nav-padding-horizontal !default;
$nav-subtitle-font-size:                         12px !default;
$nav-subtitle-line-height:                       18px !default;
$nav-nested-padding-left:                        15px !default;
$nav-nested-padding-vertical:                    2px !default;
$nav-nested-padding-horizontal:                  0 !default;
$nav-sub-padding-vertical:                       5px !default;
$nav-sub-padding-left:                           $nav-padding-horizontal !default;
$nav-parent-icon:                                "\f104" !default;
$nav-parent-open-icon:                           "\f107" !default;
$nav-parent-icon-width:                          20px !default;
$nav-parent-icon-margin-right:                   -10px !default;
$nav-side-color:                                 #444 !default;
$nav-side-hover-background:                      rgba(0,0,0,0.05) !default;
$nav-side-hover-color:                           #444 !default;
$nav-side-active-background:                     #00a8e6 !default;
$nav-side-active-color:                          #fff !default;
$nav-side-header-color:                          #444 !default;
$nav-side-divider-border:                        #ddd !default;
$nav-side-divider-border-width:                  1px !default;
$nav-side-nested-color:                          #07D !default;
$nav-side-nested-hover-color:                    #059 !default;
$nav-dropdown-color:                             #444 !default;
$nav-dropdown-hover-background:                  #00a8e6 !default;
$nav-dropdown-hover-color:                       #fff !default;
$nav-dropdown-header-color:                      #999 !default;
$nav-dropdown-divider-border:                    #ddd !default;
$nav-dropdown-divider-border-width:              1px !default;
$nav-dropdown-nested-color:                      #07D !default;
$nav-dropdown-nested-hover-color:                #059 !default;
$nav-navbar-color:                               #444 !default;
$nav-navbar-hover-background:                    #00a8e6 !default;
$nav-navbar-hover-color:                         #fff !default;
$nav-navbar-header-color:                        #999 !default;
$nav-navbar-divider-border:                      #ddd !default;
$nav-navbar-divider-border-width:                1px !default;
$nav-navbar-nested-color:                        #07D !default;
$nav-navbar-nested-hover-color:                  #059 !default;
$nav-offcanvas-padding-vertical:                 10px !default;
$nav-offcanvas-padding-horizontal:               15px !default;
$nav-offcanvas-color:                            #ccc !default;
$nav-offcanvas-hover-background:                 #404040 !default;
$nav-offcanvas-hover-color:                      #fff !default;
$nav-offcanvas-active-background:                #1a1a1a !default;
$nav-offcanvas-active-color:                     #fff !default;
$nav-offcanvas-header-color:                     #777 !default;
$nav-offcanvas-divider-border:                   #1a1a1a !default;
$nav-offcanvas-divider-border-width:             1px !default;
$nav-offcanvas-nested-color:                     #ccc !default;
$nav-offcanvas-nested-hover-color:               #fff !default;
$navbar-background:                              #eee !default;
$navbar-color:                                   #444 !default;
$navbar-link-color:                              #07D !default;
$navbar-link-hover-color:                        #059 !default;
$navbar-nav-height:                              40px !default;
$navbar-nav-line-height:                         $navbar-nav-height !default;
$navbar-nav-padding-horizontal:                  15px !default;
$navbar-nav-color:                               #444 !default;
$navbar-nav-font-size:                           14px !default;
$navbar-nav-font-weight:                         normal !default;
$navbar-nav-font-family:                         "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$navbar-nav-hover-background:                    #f5f5f5 !default;
$navbar-nav-hover-color:                         #444 !default;
$navbar-nav-onclick-background:                  #ddd !default;
$navbar-nav-onclick-color:                       #444 !default;
$navbar-nav-active-background:                   #f5f5f5 !default;
$navbar-nav-active-color:                        #444 !default;
$navbar-nav-subtitle-font-size:                  10px !default;
$navbar-nav-subtitle-offset:                     2px !default;
$navbar-brand-font-size:                         18px !default;
$navbar-brand-color:                             #444 !default;
$navbar-brand-hover-color:                       #444 !default;
$navbar-toggle-font-size:                        18px !default;
$navbar-toggle-color:                            #444 !default;
$navbar-toggle-hover-color:                      #444 !default;
$navbar-toggle-icon:                             "\f0c9" !default;
$navbar-toggle-icon-alt:                         "\f002" !default;
$modal-z-index:                                  1010 !default;
$modal-background:                               rgba(0,0,0,0.6) !default;
$modal-dialog-margin-vertical:                   50px !default;
$modal-dialog-padding:                           20px !default;
$modal-dialog-width:                             600px !default;
$modal-dialog-background:                        #fff !default;
$modal-dialog-large-width:                       930px !default;
$modal-dialog-large-width-large:                 1130px !default;
$modal-header-margin-bottom:                     15px !default;
$modal-footer-margin-top:                        15px !default;
$modal-caption-margin-bottom:                    -10px !default;
$modal-caption-color:                            #fff !default;
$list-nested-padding-left:                       20px !default;
$list-line-margin-top:                           5px !default;
$list-line-border:                               #ddd !default;
$list-line-border-width:                         1px !default;
$list-striped-padding-vertical:                  5px !default;
$list-striped-padding-horizontal:                5px !default;
$list-striped-background:                        #f5f5f5 !default;
$list-space-margin-top:                          10px !default;
$pagination-font-size:                           1rem !default;
$pagination-margin-left:                         5px !default;
$pagination-padding-vertical:                    3px !default;
$pagination-padding-horizontal:                  5px !default;
$pagination-line-height:                         20px !default;
$pagination-background:                          #eee !default;
$pagination-color:                               #444 !default;
$pagination-hover-background:                    #f5f5f5 !default;
$pagination-hover-color:                         #444 !default;
$pagination-onclick-background:                  #ddd !default;
$pagination-onclick-color:                       #444 !default;
$pagination-active-background:                   #00a8e6 !default;
$pagination-active-color:                        #fff !default;
$pagination-disabled-background:                 #f5f5f5 !default;
$pagination-disabled-color:                      #999 !default;
$panel-title-margin-bottom:                      15px !default;
$panel-title-font-size:                          18px !default;
$panel-title-line-height:                        24px !default;
$panel-title-font-weight:                        normal !default;
$panel-title-color:                              #444 !default;
$panel-title-text-transform:                     none !default;
$panel-teaser-margin-bottom:                     $panel-title-margin-bottom !default;
$panel-box-padding:                              15px !default;
$panel-box-background:                           #f5f5f5 !default;
$panel-box-color:                                #444 !default;
$panel-box-hover-color:                          $panel-box-color !default;
$panel-box-title-color:                          #444 !default;
$panel-box-badge-top:                            10px !default;
$panel-box-badge-right:                          $panel-box-badge-top !default;
$panel-box-teaser-margin:                        -$panel-box-padding !default;
$panel-box-primary-background:                   #ebf7fd !default;
$panel-box-primary-color:                        #2d7091 !default;
$panel-box-primary-hover-color:                  $panel-box-primary-color !default;
$panel-box-primary-title-color:                  #2d7091 !default;
$panel-box-secondary-background:                 #eee !default;
$panel-box-secondary-color:                      #444 !default;
$panel-box-secondary-hover-color:                $panel-box-secondary-color !default;
$panel-box-secondary-title-color:                #444 !default;
$panel-hover-padding:                            15px !default;
$panel-hover-color:                              #444 !default;
$panel-hover-hover-background:                   #f5f5f5 !default;
$panel-hover-hover-color:                        #444 !default;
$panel-hover-badge-top:                          10px !default;
$panel-hover-badge-right:                        $panel-hover-badge-top !default;
$panel-hover-teaser-margin:                      -$panel-hover-padding !default;
$panel-header-title-padding:                     10px !default;
$panel-header-title-border:                      #ddd !default;
$panel-header-title-border-width:                1px !default;
$panel-header-title-color:                       #444 !default;
$panel-space-padding:                            30px !default;
$panel-divider-gutter:                           25px !default;
$panel-divider-gutter-large:                     35px !default;
$panel-divider-border:                           #ddd !default;
$panel-divider-border-width:                     1px !default;
$panel-body-padding:                             $panel-box-padding !default;
$overlay-panel-padding:                          20px !default;
$overlay-panel-color:                            #fff !default;
$overlay-panel-background:                       rgba(0,0,0,0.5) !default;
$overlay-icon:                                   "\f002" !default;
$overlay-icon-size:                              50px !default;
$overlay-icon-color:                             #fff !default;
$overlay-fade-in-opacity:                        1 !default;
$overlay-fade-out-opacity:                       0.7 !default;
$overlay-scale-in-scale:                         1.1 !default;
$overlay-scale-out-scale:                        1 !default;
$overlay-spin-in-scale:                          1.1 !default;
$overlay-spin-out-scale:                         1 !default;
$overlay-spin-in-rotate:                         3deg !default;
$overlay-spin-out-rotate:                        0deg !default;
$overlay-panel-link-color:                       inherit !default;
$overlay-panel-link-text-decoration:             underline !default;
$overlay-area-background:                        rgba(0,0,0,0.3) !default;
$overlay-area-icon:                              "\f002" !default;
$overlay-area-icon-size:                         50px !default;
$overlay-area-icon-color:                        #fff !default;
$overlay-area-content-font-size:                 1rem !default;
$overlay-area-content-padding-horizontal:        15px !default;
$overlay-area-content-color:                     #fff !default;
$overlay-area-content-link-color:                inherit !default;
$overlay-caption-background:                     rgba(0,0,0,0.5) !default;
$overlay-caption-padding:                        15px !default;
$overlay-caption-color:                          #fff !default;
$offcanvas-z-index:                              1000 !default;
$offcanvas-background:                           rgba(0,0,0,0.1) !default;
$offcanvas-bar-width:                            270px !default;
$offcanvas-bar-background:                       #333 !default;
$offcanvas-panel-margin:                         20px 15px !default;
$offcanvas-panel-color:                          #777 !default;
$offcanvas-panel-link-color:                     #ccc !default;
$offcanvas-panel-link-hover-color:               #fff !default;
$offcanvas-panel-title-color:                    $offcanvas-panel-link-color !default;
$subnav-margin-horizontal:                       10px !default;
$subnav-margin-vertical:                         $subnav-margin-horizontal !default;
$subnav-color:                                   #444 !default;
$subnav-hover-color:                             #07D !default;
$subnav-hover-text-decoration:                   none !default;
$subnav-active-color:                            #07D !default;
$subnav-line-border-height:                      10px !default;
$subnav-line-border:                             #ddd !default;
$subnav-line-border-width:                       1px !default;
$subnav-pill-padding-vertical:                   3px !default;
$subnav-pill-padding-horizontal:                 9px !default;
$subnav-pill-hover-background:                   #eee !default;
$subnav-pill-hover-color:                        #444 !default;
$subnav-pill-active-background:                  #00a8e6 !default;
$subnav-pill-active-color:                       #fff !default;
$subnav-disabled-color:                          #999 !default;
$tab-border:                                     #ddd !default;
$tab-border-width:                               1px !default;
$tab-padding-horizontal:                         12px !default;
$tab-padding-vertical:                           8px !default;
$tab-padding-top:                                $tab-padding-vertical !default;
$tab-padding-bottom:                             $tab-padding-vertical !default;
$tab-margin-horizontal:                          5px !default;
$tab-margin-vertical:                            5px !default;
$tab-color:                                      #07D !default;
$tab-hover-border:                               #f5f5f5 !default;
$tab-hover-background:                           #f5f5f5 !default;
$tab-hover-color:                                #059 !default;
$tab-active-border:                              #ddd !default;
$tab-active-background:                          #fff !default;
$tab-active-color:                               #444 !default;
$tab-disabled-color:                             #999 !default;
$table-margin-vertical:                          15px !default;
$table-padding-vertical:                         8px !default;
$table-padding-horizontal:                       8px !default;
$table-caption-color:                            #999 !default;
$table-caption-font-size:                        12px !default;
$table-striped-background:                       #f5f5f5 !default;
$table-condensed-padding-vertical:               4px !default;
$table-condensed-padding-horizontal:             8px !default;
$table-hover-background:                         #EEE !default;
$table-active-background:                        $table-hover-background !default;
$text-small-font-size:                           11px !default;
$text-small-line-height:                         16px !default;
$text-large-font-size:                           18px !default;
$text-large-line-height:                         24px !default;
$text-large-font-weight:                         normal !default;
$text-muted-color:                               #999 !default;
$text-primary-color:                             #2d7091 !default;
$text-success-color:                             #659f13 !default;
$text-warning-color:                             #e28327 !default;
$text-danger-color:                              #d85030 !default;
$text-contrast-color:                            #fff !default;
$thumbnail-padding:                              4px !default;
$thumbnail-border:                               #ddd !default;
$thumbnail-border-width:                         1px !default;
$thumbnail-background:                           #fff !default;
$thumbnail-hover-border:                         #aaa !default;
$thumbnail-hover-background:                     #fff !default;
$thumbnail-caption-padding:                      $thumbnail-padding !default;
$thumbnail-caption-color:                        #444 !default;
$thumbnail-mini-width:                           150px !default;
$thumbnail-small-width:                          200px !default;
$thumbnail-medium-width:                         300px !default;
$thumbnail-large-width:                          400px !default;
$thumbnav-margin-horizontal:                     10px !default;
$thumbnav-margin-vertical:                       $thumbnav-margin-horizontal !default;
$thumbnav-background:                            #fff !default;
$thumbnav-opacity:                               0.7 !default;
$thumbnav-hover-opacity:                         1 !default;
$thumbnav-active-opacity:                        1 !default;
$utility-container-max-width:                    980px !default;
$utility-container-padding-horizontal:           25px !default;
$utility-container-large-max-width:              1200px !default;
$utility-container-large-padding-horizontal:     35px !default;
$utility-align-horizontal:                       15px !default;
$utility-align-vertical:                         15px !default;
$utility-height-viewport-min-height:             600px !default;
$utility-margin:                                 15px !default;
$utility-margin-small:                           5px !default;
$utility-margin-large:                           50px !default;
$utility-border-rounded:                         5px !default;
$utility-heading-large-small-font-size:          36px !default;
$utility-heading-large-small-line-height:        42px !default;
$utility-heading-large-font-size:                52px !default;
$utility-heading-large-line-height:              64px !default;
$utility-link-muted-color:                       #444 !default;
$utility-link-muted-hover-color:                 #444 !default;
$utility-scrollable-text-height:                 300px !default;
$utility-scrollable-box-height:                  170px !default;
$utility-scrollable-box-padding:                 10px !default;
$utility-scrollable-box-border:                  #ddd !default;
$utility-scrollable-box-border-width:            1px !default;
$breakpoint-small:                               480px !default;
$breakpoint-medium:                              768px !default;
$breakpoint-large:                               960px !default;
$breakpoint-xlarge:                              1220px !default;
$breakpoint-mini-max:                            ($breakpoint-small - 1) !default;
$breakpoint-small-max:                           ($breakpoint-medium - 1) !default;
$breakpoint-medium-max:                          ($breakpoint-large - 1) !default;
$breakpoint-large-max:                          ($breakpoint-xlarge - 1);