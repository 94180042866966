//	The size of the buttons
$btnSize: 40px;
$padding: 10px;
$fontSize: 14px;

//	Animations
$transitionDuration: 0.4s;
$transitionFunction: ease;

$subOffset: 40%;


@import "sizing";
@import "colors";
@import "arrows";

//	Font
@mixin font
{
	-webkit-text-size-adjust: none;
	font-family: Arial, Helvetica, sans-serif;
	font-size: $fontSize;
	text-shadow: none;
}
//	Borders
@mixin border( $border, $pseudo, $size, $pos1, $pos2 )
{
	&:#{$pseudo}
	{
		content: '';
		border-#{$border}-width: 1px;
		border-#{$border}-style: solid;
		display: block;
		#{$size}: 100%;
		position: absolute;
		#{$pos1}: 0;
		#{$pos2}: 0;
	}
}
@mixin border-top
{
	@include border( "top", ":before", "width", "top", "left" );
}
@mixin border-right
{
	@include border( "right", ":after", "height", "right", "top" );
}
@mixin border-bottom
{
	@include border( "bottom", ":after", "width", "bottom", "left" );
}
@mixin border-left
{
	@include border( "left", ":before", "height", "left", "top" );
}

//	Misc mixins
@mixin vendor-prefix( $prop, $val )
{
	-webkit-#{$prop}: $val;
	-moz-#{$prop}: $val;
	-ms-#{$prop}: $val;
	-o-#{$prop}: $val;
	#{$prop}: $val;
}
@mixin ellipsis
{	
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}