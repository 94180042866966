.date-item {
	margin: 25px 0;

	.image-wrap {
		//background: $primary;
	}

	img.wp-post-image {
		max-height:30px;
		width:auto;
		margin-bottom:10px !important;
	}

	.thumb-float {
		float:left;
		line-height:1;
		margin-right:15px !important;
		padding-right:15px;
		border-right:1px solid $border-color-light;

		img {
			margin-bottom:0 !important;
		}
	}

	.text-wrap {

		header {
			margin:10px 0;
			border:none !important;
			display: block;

			* {
				margin:0;
			}
		}

		.date, .location {
			display: inline-block;
			width:100%;
			font-weight:normal;

			i {
				width:30px;
			}
		}

		span, p {
			color:$lightgrey-dd;
			font-size:.9em;
		}

		.tag-item {
			margin: 6px 0 20px !important;
		}
	}
}


.dates-wrap {

	header {
		border-bottom: 1px solid $border-color-light;
	}

	&.fairs-wrap {
		padding-left:32px;
		border-left:1px solid $border-color-light;
	}
}