.ui-autocomplete {

	&.ui-widget-content {
		border:none;
		background: none;
		font-family: inherit;
		@include borderradius(0 0 4px 4px);
    	overflow: hidden;

		.ui-menu-item {
			background: $border-color-light-l;
			color:#fff;
			padding:5px 15px;
			border:none;
			border-top:1px solid $border-color-light;
			color:$primary;
			cursor: pointer;

			&.ui-state-focus {
				background: $border-color-light;
				margin:0;
			}
		}
	}
}