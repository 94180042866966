.cutout-border-before {
	position: relative;
	padding-top:$spacer-phone-medium;

	@media (min-width:$breakpoint-small) {
		padding-top: $spacer-tablet-medium;
	}

	@media (min-width:$breakpoint-large) {
		padding-top: $spacer-medium;
	}

	&:before {
		content:"";
		position:absolute;
		top:0;
		left:0;
		width: 0;
		height: 0;
		border-bottom: 15px solid $primary;
		border-left: 100vw solid transparent;
		//-webkit-backface-visibility: hidden;
		@include transformRotate(-2deg);

		@media(min-width:$breakpoint-medium){
			border-bottom: 25px solid $primary;
		}

		@media(min-width:$breakpoint-xlarge){
			border-left: $breakpoint-xlarge solid transparent;
		}
	}
}

@media(min-width: $breakpoint-medium) {

	.cutout {
		position: relative;
		padding: 45px 50px;

		.cutout-inner {
			z-index:1;
			position: relative;
		}

		&.cutout-nopadding {
			padding:0;
		}

		&.cutout-smallpadding {
			//padding:10px 35px;
		}

		&:before {
			content:"";
			//z-index:-2;
			position:absolute;
			background:$primary;
			left:-1%;
			top:0;
			width:100%;
			height:100%;
			//-webkit-backface-visibility: hidden;
			@include transformRotate(1deg);		
		}

		&:after {
			content:"";
			//z-index:-1;
			position:absolute;
			background:#fff;
			left:0;
			top:0;
			width:100%;
			height:100%;
		}

		&.cutout-inverse {
			&:before {
				background:#fff;
			}

			&:after {
				background:$primary;
			}
		}


		&.cut2:before {
			left:1%;
		}
		&.cut3:before {
			left:-1%;
			top:-1%;
			width:102%;
			height:99%;
			@include transformRotate(-.65deg);	
		}


		&.cutout-hover {
			&:before {
				left:0;
				@include ease(all 0.2s);
				@include transformRotate(0);	
			}

			&:hover {
				&:before {
					left:-1%;
					@include transformRotate(1deg);	
				}
			}
		}
	}

}