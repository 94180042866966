.show-print {
	display:none;
}

@media print {
	.hide-print {
		display:none !important;
	}
	.show-print {
		display:block !important;
	}
	nav  { display:none;}	
	body {
		background-color:transparent;
		padding: 0;
		margin: 0;
	}
	.more-wrap, article header, article.welcome-block .welcome-inner, main {
		display: block !important;
	}
	#main-header {
		page-break-inside: avoid;
		text-align: center;
		
		img {
			max-height: 20mm;
		}
	}
	table.searchresults {
		img {
			max-width: 15mm;
		}
		td, th {
			page-break-inside: avoid;
		}
	}
	div#searchresults, div#datagrid, div.datagrid {
		input
		  {
		    border: 0;
		    padding:0;
		    overflow:visible;
		  }
	}
}