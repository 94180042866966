.list-wrap {

	ul {
		@media(min-width:$breakpoint-xlarge){
			margin-left:-65px;
		}

		li {
			margin:0;

			@media(min-width:$breakpoint-xlarge){
				padding-left:65px;
			}

			.text {
				color:darken($lightgrey-d,15%);

				h3 {
					margin-top:12px;
					margin-bottom:8px;

					&:before {
						font-family:"FontAwesome";
						content:"\f101";
						color:$secondary;
						font-size: .85em;
						margin-right:10px;
						display: inline-block;
						vertical-align: top;
					}
				}

				span {
					margin-bottom:15px;
				}
			}

			.image {
				float:right;
				margin-left:20px;
				width:120px;
				text-align: center;

				img {
					display: inline-block;
					width:auto;
					height:auto;
					max-height:120px;
					max-width:120px;
				}
			}
		}
	}
}