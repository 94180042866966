.slider-item {
	position:relative;
	margin: 0 !important;
	font-size: 0.8em;
	padding:10px 0;

	@media(max-width:$breakpoint-small-max) {
		border-bottom:2px solid $border-color-light;
	}

	.slick-slider {
		max-width: 1920px;
		margin:0 auto 30px auto;
	}

	.slick-next, .slick-prev {
		display:none !important;
	}

	@media(min-width:$breakpoint-xlarge){
		padding:15px 20px;

		.slick-next, .slick-prev {
			display:block !important;
		}
	}

	.container.display-table {
		display: table;
    	height: 100%;

    	.display-table-cell {
    		display:table-cell;
    		vertical-align: middle;
    	}
	}

	.inner-text {
		position: relative;

		@media (min-width:$breakpoint-xlarge) {
			padding: 35px 0 25px 55px;

			&:before {
				position: absolute;
				left:0;
				top:0;
				bottom: 0;
				background:url(../img/famo-cutout-white.png) no-repeat;
				background-size: 100%;
				content:"";
				width:600px;
			}
		}
	}

	&+article {
		margin-top:30px;
	}

	&.no-maxheight .image-wrap {
		max-height:none;
	}

	.image-wrap {
		width:100%;
		overflow:hidden;
		@media (min-width:$breakpoint-medium) {
			-webkit-backface-visibility: hidden;
			max-height:56.4vh;
			overflow:hidden;
		}

		img {
			max-width: none;
			float:left;
			width:100%;
			height: auto;
			//@include opacity(0.5);

			&.no-opacity {
				@include opacity(1);
			}
		}
	}

	.text-wrap {
		padding:0 15px;
		font-size:.7em;
		@media (min-width:$breakpoint-medium) {
			position: absolute;
			top:0;
			left:0;
			bottom:0;
			right:0;
			width:100%;
			height:100%;

			* {
				color:#fff;
			}
		}
		@media (min-width:$breakpoint-large) {
			
		}
		@media (min-width:$breakpoint-xlarge) {
			font-size:1em;
			padding:0;
		}

		h2 {
			font-size:2em;
		}
	}

	& + .background-rotate {
		margin:-4% 0 !important;
	}

	&:last-child {
		margin-bottom:50px !important;
	}


	&.no-img {

		.image-wrap {
			position: absolute;
			left: 0;
			top:0;
			height:100%;
		}

		.text-wrap {
			position:relative;
			padding:80px 0;

			* {
				color:$primary;
			}
		}
	}

}





/*

.slider-99 {
	max-width:1250px;
	margin:0 auto;
	width:100%;
	position:relative;
	padding:15px 0!important;
	font-size: 0.8em;

	.container.display-table {
		display: table;
    	height: 100%;

    	.display-table-cell {
    		display:table-cell;
    		vertical-align: middle;

    		header {
    			max-width:900px;
    			padding-left:50px;
    			font-size:.8em;
    		}
    	}
	}

	&+article {
		margin-top:30px;
	}

	&.no-maxheight .image-wrap {
		max-height:none;
	}

	.image-wrap {
		width:100%;
		overflow:hidden;
		@media (min-width:$breakpoint-medium) {
			-webkit-backface-visibility: hidden;
			max-height:56vh;
			overflow:hidden;
		}

		img {
			max-width: none;
			float:left;
			width:100%;
			@include opacity(0.5);

			&.no-opacity {
				@include opacity(1);
			}
		}
	}

	.text-wrap {
		padding:0 15px;
		font-size:.7em;
		@media (min-width:$breakpoint-medium) {
			position: absolute;
			top:0;
			left:0;
			bottom:0;
			right:0;
			width:100%;
			height:100%;

			* {
				color:#fff;
			}
		}
		@media (min-width:$breakpoint-large) {
			font-size:1em;
		}
		@media (min-width:$breakpoint-xlarge) {
			padding:0;
		}
	}

	& + .background-rotate {
		margin:-4% 0 !important;
	}

	&:last-child {
		margin-bottom:50px !important;
	}


	&.no-img {

		.image-wrap {
			position: absolute;
			left: 0;
			top:0;
			height:100%;
		}

		.text-wrap {
			position:relative;
			padding:80px 0;

			* {
				color:$primary;
			}
		}
	}

}


.slider-1 {
	position:relative;
	margin: 0 !important;
	font-size: 0.8em;
	padding:10px 0;

	@media(min-width:$breakpoint-xlarge){
		padding:15px 20px;
	}

	.container.display-table {
		display: table;
    	height: 100%;

    	.display-table-cell {
    		display:table-cell;
    		vertical-align: middle;
    	}
	}

	&+article {
		margin-top:30px;
	}

	&.no-maxheight .image-wrap {
		max-height:none;
	}

	.image-wrap {
		width:100%;
		overflow:hidden;
		@media (min-width:$breakpoint-medium) {
			-webkit-backface-visibility: hidden;
			max-height:56vh;
			overflow:hidden;
		}

		img {
			max-width: none;
			float:left;
			width:100%;
			@include opacity(0.5);

			&.no-opacity {
				@include opacity(1);
			}
		}
	}

	.text-wrap {
		padding:0 15px;
		font-size:.7em;
		@media (min-width:$breakpoint-medium) {
			position: absolute;
			top:0;
			left:0;
			bottom:0;
			right:0;
			width:100%;
			height:100%;

			* {
				color:#fff;
			}
		}
		@media (min-width:$breakpoint-large) {
			font-size:1em;
		}
		@media (min-width:$breakpoint-xlarge) {
			padding:0;
		}
	}
	&:last-child {
		margin-bottom:50px !important;
	}


	&.no-img {

		.image-wrap {
			position: absolute;
			left: 0;
			top:0;
			height:100%;
		}

		.text-wrap {
			position:relative;
			padding:80px 0;

			* {
				color:$primary;
			}
		}
	}

}



.slider-0 {
	position:relative;
	padding:2% 0;
	margin: 0 !important;

	.container.display-table {
		display: table;
    	height: 100%;

    	.display-table-cell {
    		display:table-cell;
    		vertical-align: middle;
    	}
	}

	&+article {
		margin-top:50px;
	}

	&.no-maxheight .image-wrap {
		max-height:none;
	}

	.image-wrap {
		//background: $primary;
		margin-left:-1%;
		width:102%;
		overflow:hidden;
		@media (min-width:$breakpoint-medium) {
			@include transformRotate(-2deg);
			-webkit-backface-visibility: hidden;
			max-height:57vh;
			overflow:hidden;
		}

		img {
			max-width: none;
			float:left;
			width:100%;
			@include opacity(0.5);
			@media (min-width:$breakpoint-medium) {
				@include transformScaleRotate(1.12, 2deg);
			}

			&.no-opacity {
				@include opacity(1);
			}
		}
	}

	.text-wrap {
		padding:0 15px;
		font-size:.7em;
		@media (min-width:$breakpoint-medium) {
			position: absolute;
			top:0;
			left:0;
			bottom:0;
			right:0;
			width:100%;
			height:100%;

			* {
				color:#fff;
			}
		}
		@media (min-width:$breakpoint-large) {
			font-size:1em;
		}
		@media (min-width:$breakpoint-xlarge) {
			padding:0;
		}
	}

	& + .background-rotate {
		margin:-4% 0 !important;
	}

	&:last-child {
		margin-bottom:50px !important;
	}


	&.no-img {

		.image-wrap {
			position: absolute;
			left: 0;
			top:0;
			height:100%;
		}

		.text-wrap {
			position:relative;
			padding:80px 0;

			* {
				color:$primary;
			}
		}
	}

}
*/