.has-tip {
	float:right;
	margin-right:2px;
	color:$primary;
}

.lieferdaten-item {
	margin-top:20px;
	display: inline-block;
    width: 100%;

    &:first-child {
    	margin-top:0;
    }

    &.searchform-inner {
    	margin-bottom:0 !important;
    }
}