footer {
	background: $primary;
	padding:30px 15px 50px;
	color:#fff;
	position: relative;

	@media (min-width:$breakpoint-large) {
		padding:70px 0 100px;
	}

	&:before {
		content:"";
		position:absolute;
		top:-35px;
		left:0;
		width: 0;
		height: 0;
		border-bottom: 35px solid $primary;
		border-left: 100vw solid transparent;
	}

	.jobs-wrap {

		.job {
			margin:20px 0;

			* {
				margin:0;
			}
		}
	}




	// Navigation 

	#footer-nav {

		ul {
			list-style-type: none;
			margin:0 0 20px 0;

			li {
				margin-bottom:10px;
				
				a, span {
					font-size:1.2em;
					//margin-bottom:10px;
					text-transform: uppercase;
					color:#fff;
					display: inline-block;
				}

				a[target="_blank"] {
								
					&:before {
						font-family: "FontAwesome";
						display: inline-block;
						vertical-align: bottom;
						content:"\f08e";
						margin-right:10px;
					}
				}

				a:hover {
					color:darken($border-color-light,10%);
				}

				ul {
					margin:10px 0 20px;
					padding:0;

					li {
						margin-bottom: 0;

						a {
							font-size: .9em;
							margin-bottom: 0;
							text-transform: none;
							color:darken($border-color-light,10%);

							&:hover {
								color:#fff;
							}

							i {
								font-size:90%;
								width:17px;
								float:left;
								text-align: center;
								margin-right:10px;
							}
						}
					}
				}
			}
		}
	}

}