.sister-sites-wrap {

	ul {

		li {

			a {
				border-left:1px solid $border-color-light;
				color:$primary;
				display: inline-block;
				width:100%;
				padding:4px 5px 4px 30px;
				@include ease(all .2s);

				&:hover {
					border-left-color:$secondary;
					color:$secondary;
				}
			}
		}
	}
}