table {
	font-size:.9em;
	border-spacing: 0;
    border-collapse: collapse;
	width:100%;
	margin:20px 0;

	img {
		width:auto;
		display: inline-block;
		max-height:45px;
		max-width:45px;

    @media(min-width: $breakpoint-medium) {
      max-height:90px;
  		max-width:90px;
    }
	}

	td, th {
		padding:5px 10px;

		small {
			display: inline-block;
			width:100%;
		}

		&.nowrap {
			white-space: nowrap;
		}
	}

	thead {

		th {
			font-family:OfficinaSansITCTTBold, sans-serif;
			font-size:.9em;
			padding: 15px 10px;
			text-transform: uppercase;
			border-bottom:1px solid $border-color-light;
			border-top:1px solid $border-color-light;

			&:first-child {
				border-left:1px solid $border-color-light;
			}
			&:last-child {
				border-right:1px solid $border-color-light;
			}

			&.footable-sortable {
				cursor: pointer;

				.footable-sort-indicator:before {
					font-family: "FontAwesome";
					content:"\f0dc";
					margin-left:8px;
					font-size: 14px;
					color:$lightgrey-d;
				}

				&.footable-sorted .footable-sort-indicator:before {
					content:"\f0de";
					color:$secondary;
				}
				&.footable-sorted-desc .footable-sort-indicator:before {
					content:"\f0dd";
					color:$secondary;
				}
			}
		}
	}


	tbody {
		color:$grey-l;

		tr {

			&:hover {
				background: #F1F2F5;
			}

			td {
				border-bottom:1px solid $border-color-light;
			}

			&:last-child td {
				border-bottom: none;
			}
		}
	}

	.link {
		display: inline-block;
		width:100%;
		color: $primary;
		padding:7px 15px;
		@include borderradius(100px);
		@include ease(all 0.2s);
		background:$border-color-light;

		@media(max-width:$breakpoint-xlarge) {
			display: inline-block;
			width:100%;
			@include borderradius(5px);
		}

		&:hover {
			background: $secondary;
			color:#fff;
		}
	}



	&.responsive-accordion {
		position: relative;

		thead {
			position: relative;

			@media(max-width:$breakpoint-medium){

				&:before {
					font-family: "FontAwesome";
					content:"\f0fe";
					position: absolute;
					left: 14px;
				    top: 16px;
				    font-size: 18px;
				    color:$secondary;
				}

				&.toggled:before {
					content:"\f146";
				}
			}
		}

		tbody {
			display:none;

			@media(min-width:$breakpoint-medium){
				display:table-row-group !important;
			}
		}
	}
}


.footable-row-detail {

	.footable-row-detail-row {
		display: inline-block;
		width:100%;

		.footable-row-detail-name {
			float:left;
			width:33.333%;
			text-align: right;
			padding-right:10px;
		}

		.footable-row-detail-value {
			float:left;
			width:66.666%;
		}
	}
}

table.table-jobs {

	tbody {

		tr {

			td {
				&.job-location {
					color:$lightgrey-dd;

					i {
						width:20px;
					}
				}

				&.job-title {
					a, a:hover, a:visited, a:focus {
						background-color:$lightgrey-dd;
						font-family:OfficinaSansITCTTBold, sans-serif;
						font-size:.9em;
						}
					}
				}
			}
		}
}
