#socials-bar {
	display:none;

	@media(min-width:$breakpoint-medium) {
		float:left;
		display:block;

		a {
			float:left;
			margin-right:10px;
			color: #8993AD;
			padding-top:15px;
			font-size:.8em;
			@include ease(all .2s);

			&:hover {
				color: $primary;
			}
		}
	}
}