*, *:before, *:after {
	box-sizing: border-box;
}

a {
	cursor:pointer;
	text-decoration: none;
	color:$secondary;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}

	&:hover {
		color:$secondary-l;
	}

	&:active {
		color:$secondary-d;
	}

	&:focus {
		color:$secondary;
	}

	i {
		margin-right:7px;

		&.pos-behind {
			margin-right:0;
			margin-left:7px;
		}
	}
}

button, button:hover, button:active, button:focus {
	background:none;
	border:0;
	outline:0;
	padding:0;
	cursor: pointer;
}

img {
	max-width:100%;
	height:auto;
}


:focus {
	outline:none !important;
}

.text-center { text-align:center; }
.text-left { text-align:left; }
.text-right { text-align:right; }


.clear-both {
	clear:both;
}