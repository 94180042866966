#standortwahl {
	margin:30px 0 80px;
	display: inline-block;
	width:100%;

	.text, .karte {
		float:left;
		width:100%;

		@media(min-width:$breakpoint-large){
			width:50%;
		}
	}

	ul {
		list-style-type: none;
		padding:0;

		li {
			padding-left:15px;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top:0;
				color:$secondary;
				font-family: "FontAwesome";
				content:"\f105";
				font-size: .8em;
			}
		}
	}

	.karte {
		position:relative;

		.marker-wrap {
			position: relative; 
			z-index:999;
			float:right;
		}

		.map-image {
			position: absolute;
			right: 0;
			top: -15px;
			height:480px
		}
	}
}

.spamschutz-headline {
	margin-bottom: 20px !important;
    margin-top: 12px;
    border-bottom: 1px solid $border-color-light;
    padding-bottom: 12px;
}

#register-form-wrap {
	display:none;
	padding-top:30px;

	input {
		background:#fff;
	}

	label { cursor:pointer; }

	.register-item {
		margin:8px 0;
	}

	.bereits-kunde {
		border:1px solid $border-color-light;
		background: $border-color-light-l;
		padding:10px 20px;

		.question {
			display: inline-block;
	    	padding-top: 9px;
    	}
	}

	.region-wrap {
		margin-bottom:35px;
	}
}